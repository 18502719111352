import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const StyledOuterWrapper = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ::after{
    position: absolute;
    content: '';
    width: 1px;
    height: 50%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.color.secondary};
  }
  :nth-child(even){
    ::after{
      display: none;
    }
  }

  @media screen and (min-width: ${breakpoints.L}) {
    :nth-child(even){
      ::after{
        display: block;
      }
    }
    :last-child{
      ::after{
          display: none;
      }
    }
  }
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledHeading = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.secondary};
    
  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
    
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const StyledFigure = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-family: ${({ theme }) => theme.fontFamily.serif};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.primary};

  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.xxxl};
  }

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: 12.8rem;
  }
`;

const StyledDescription = styled.p`
  display: block;
  width: 70%;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.secondary};
  text-align: center;

  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.xxs};
  }

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

const FigureItem = ({ figureHeading = '', figureFigure = '', figureDescription = '' }) => {
  const figureRef = useRef(null);

  const changeFigure = (target, to) => {
    if (target) {
      let start = 0;
      const animated = target;
      const time = 500 / to;
      const interval = setInterval(() => {
        animated.textContent = start;
        if (start === to) { clearInterval(interval); }
        start += 1;
      }, time);
    }
  };

  useEffect(() => {
    const figure = +figureRef.current.textContent;

    gsap.from(figureRef.current, {
      duration: 0.6,
      opacity: 0.4,
      stagger: 1,
      scrollTrigger: {
        trigger: figureRef.current,
        start: 'top 70%',
        onEnter: () => changeFigure(figureRef.current, figure),
      },
    });
  }, []);

  return (
    <StyledOuterWrapper>
      <StyledInnerWrapper>
        <StyledHeading>
          {figureHeading}
        </StyledHeading>
        <StyledFigure
          ref={figureRef}
        >
          {figureFigure}
        </StyledFigure>
        <StyledDescription>
          {figureDescription}
        </StyledDescription>
      </StyledInnerWrapper>
    </StyledOuterWrapper>
  );
};

FigureItem.propTypes = {
  figureHeading: PropTypes.string.isRequired,
  figureFigure: PropTypes.number.isRequired,
  figureDescription: PropTypes.string.isRequired,
};

export default FigureItem;
