export const sectionsQuery = `{
  sectionOrSubpages(
    where: {sectionName_in: ["newsSection", "figuresSection", "advantagesSection", "brandsSection", "offerSection"]}
  ) {
    sectionName
    bckImages {
      url
    }
    bckBlendHex
    bckAttachement
    dots
    sectionOrPageTitle {
      title
      arrowLength
    }
    cards {
      id
      cardTitle
      cardSubtitle
      cardParagraphs
      link{
        name
        to
      }
      cardImage {
        url
      }
    }
    rotatorSlides {
      id
      title
      image {
        url
      }
      link
      description
    }
    offersForOfferSections{
      id
      title
      description
      mainImg{
        url
      }
      secondImg{
        url
      }
      link{
        name
        to
      }
    }
    figures{
      id
      figureHeading
      figureFigure
      figureDescription
    }
    logos{
      id
      url
    }
  }
}`;