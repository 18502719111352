import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from 'components/molecules/Card';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';

const Advantages = ({ title, cards, dots }) => (
  <Section
    dots={dots}
  >
    <SectionTitle
      arrowlength={title?.arrowLength}
    >
      {title?.title}
    </SectionTitle>
    {cards.map((card, index) => (
      <Card
        id={card.id}
        key={card.id}
        cardSubtitle={card.cardSubtitle}
        cardTitle={card.cardTitle}
        cardParagraphs={card.cardParagraphs}
        cardImage={card.cardImage}
        cardTypeRight={!!((index % 2))}
        link={card.link}
      />
    ))}
  </Section>
);

Advantages.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
      ]),
    ),
  ).isRequired,
  dots: PropTypes.bool,
};

Advantages.defaultProps = {
  dots: false,
};

const mapStateToProps = ({
  data: {
    advantagesSection: {
      title = {}, cards = [], dots,
    } = {},
  },
}) => ({
  title,
  cards,
  dots,
});

export default connect(mapStateToProps)(Advantages);
