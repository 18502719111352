import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Linkto from 'components/atoms/Linkto';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';
import circles from 'assets/icons/circles.svg';
import { routes } from 'routes';

const StyledMainOfferWrapper = styled.ul`
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 7rem;
  
  @media screen and (min-width: ${breakpoints.L}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledOfferWrapper = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;

  :nth-child(even){
    flex-direction: column-reverse;
  .txt-box{
    top: 0;
  }
  }
`;

const StyledOfferImgBox = styled.div`
  width: 100%;
  height: 0;
  padding: 50% 0;
  background-image: ${({ img }) => img && `url(${img?.url})`};
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-width: 150px;
  min-height: 150px;
`;

const StyledSquareSpacer = styled.div`
  height: 0;
  margin: 50% 0;
`;

const StyledOfferTxtBox = styled.div`
  position: absolute;
  top: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem 2rem;
  margin-left: -1rem;
  
  @media screen and (min-width: 1050px) {
    padding: 3rem;
  }
  @media screen and (min-width: ${breakpoints.L}) {
    padding: 1.5rem 2rem;
  }
  
  @media screen and (min-width: ${breakpoints.XL}) {
    padding: 3rem;
  }
`;

const StyledOfferParagraph = styled.p`
  margin-bottom: 2rem;
  :last-child{
    margin-bottom: 0;
  }
`;

const StyledOfferTxtHeading = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.color.secondary};
  
  @media screen and (min-width: 1050px) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const StyledOfferTxt = styled.div`
  margin-top: 3rem;
  margin-left: 2rem;
  margin-bottom: auto;
  
  @media screen and (min-width: 1050px) {
    margin-top: 5rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: 3rem;
  }
  
  @media screen and (min-width: ${breakpoints.XL}) {
    margin-top: 5rem;
  }
`;

const StyledLink = styled(Linkto)`
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

const StyledOfferBck = styled(ReactSVG)`
    position: absolute;
    z-index: -1;
    color: ${({ theme }) => theme.color.primaryLight};;
    top: 0;
    left: -16rem;
    >svg{
      width: 100%;
    }
    
`;

const DesktopOfferBoxes = ({ className, offers }) => (
  <StyledMainOfferWrapper
    className={className}
  >

    {offers.map(({
      title, description, mainImg, link,
    }, index) => (
      <StyledOfferWrapper
        key={index}
      >
        <StyledOfferImgBox
          img={mainImg}
        />
        <StyledSquareSpacer />
        <StyledOfferTxtBox
          className="txt-box"
        >
          <StyledOfferTxtHeading>
            {title}
          </StyledOfferTxtHeading>
          <StyledOfferTxt>
            {description.split('|').map((par, index) => (
              <StyledOfferParagraph
                key={index}
              >
                {par}
              </StyledOfferParagraph>
            ))}
          </StyledOfferTxt>
          <StyledLink
            to={routes[link.to]}
          >
            {link.name}
          </StyledLink>
        </StyledOfferTxtBox>
      </StyledOfferWrapper>
    ))}
    <StyledOfferBck
      src={circles}
    />
  </StyledMainOfferWrapper>
);

DesktopOfferBoxes.propTypes = {
  className: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    ),
  ).isRequired,
};

const mapStateToProps = ({
  data: {
    offerSection: {
      offers = [],
    } = {},
  },
}) => ({
  offers,
});

export default connect(mapStateToProps)(DesktopOfferBoxes);
