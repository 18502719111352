import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'components/molecules/Swipers/swiper.sass';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';

const StyledCarouselContainer = styled.div`
  margin: 7rem 0 3rem;
  
  @media screen and (min-width: ${breakpoints.S}) {
    margin: 12rem 0 7rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin: 13rem 0 10rem;
  }
`;

const StyledSvg = styled(ReactSVG)`
  text-align: center;
  color: white;
  svg{
    height: 25px;
    width: 100%;
  }
  
  @media screen and (min-width: ${breakpoints.XS}) {
    svg{
      height: 30px;
    }
  }
  
  @media screen and (min-width: ${breakpoints.S}) {
    svg{
      height: 50px;
    }
  }
  
  @media screen and (min-width: ${breakpoints.M}) {
    svg{
      height: 65px;
    }
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    svg{
      height: 80px;
    }
  }
  
  @media screen and (min-width: ${breakpoints.XL}) {
    svg{
      height: 100px;
    }
  }
`;

const BrandsSwiper = ({ logos }) => {
  SwiperCore.use([Autoplay]);

  return (
    <StyledCarouselContainer>
      <Swiper
        wrapperTag="ul"
        id="brands-carousel"
        spaceBetween={10}
        slidesPerView={2}
        loop="true"
        autoplay={{
          delay: 1500,
        }}
      >
        {logos.map((logo) => (
          <SwiperSlide
            tag="li"
            key={logo?.id}
            style={{ listStyle: 'none' }}
          >
            <StyledSvg
              src={logo?.url}
            />
          </SwiperSlide>
        ))}
        <div className="prev" />
      </Swiper>
    </StyledCarouselContainer>
  );
};

BrandsSwiper.propTypes = {
  logos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BrandsSwiper;
