import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/atoms/Button';

const Backdrop = styled.div`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #FFFFFFDF;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 90vw;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem 4rem;
  border: 1px solid red;
  border-radius: 1rem;
`;

const ErrorTxt = styled.p`
  margin: 1rem 0;
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.color.black};
  :last-of-type{
    margin-bottom: 4rem;
  }
`;

const ErrorModal = ({ children }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: 'RESET_ERROR' });
  };

  return (
    <Backdrop>
      <Container>
        <ErrorTxt>
          <b>
            {children}
          </b>
        </ErrorTxt>
        <ErrorTxt>
          Coś poszło nie tak! Nie zaciągnięto danych z serwera.
        </ErrorTxt>
        <ErrorTxt>
          Gdy zamkniesz ten komunikat zobaczysz naszą stronę, ale nie będzie to jej pełna wersja.
        </ErrorTxt>
        <ErrorTxt>
          Z pewnością już wiemy o problemie i pracujemy nad jego usunięciem, ale będziemy wdzięczni jeśli dodatkowo poinformujesz nas o zaistniałej sytuacji pisząc maila na nasz adres:
          {' '}
          <b>biuro@i-bluechip.pl</b>
          .
        </ErrorTxt>
        <ErrorTxt>
          Przepraszamy za utrudniania!
        </ErrorTxt>
        <Button
          onClick={handleClose}
        >
          zamknij

        </Button>
      </Container>

    </Backdrop>
  );
};

ErrorModal.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ErrorModal;
