import axios from 'axios';

export const fetchCmsData = async (query) => axios({
  method: 'post',
  url: process.env.REACT_APP_CMS_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
    'Content-Type': 'application/json',
  },
  data: {
    query,
  },
});
