import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';
import backPage from 'assets/icons/backPage.svg';
import firstPage from 'assets/icons/firstPage.svg';
import breakpoints from 'breakpoints';

const StyledPaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: 5rem auto 2rem;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.color.secondary};
  
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

const StyledForm = styled.form`
  margin-bottom: 1rem;
`;

const StyledPageInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 2rem;
    
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const StyledSelect = styled.select`
  margin-left: 1rem;
  border: none;
  color: ${({ theme }) => theme.color.secondary};
  font-family: ${({ theme }) => theme.fontFamily.standard};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  cursor: pointer;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  flex-grow: 0;
  width: 200px;
`;

const StyledPaginationButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background: none;
  border: ${({ theme }) => `2px solid ${theme.color.secondary}`};
  border-radius: 50%;
  overflow: hidden;
  transform: ${({ $rotate }) => ($rotate ? 'rotate(180deg)' : 'none')};
  cursor: pointer;
  >div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
    padding-bottom: 1px;
    >svg{
      color: ${({ theme }) => theme.color.secondary};
    }
  }
  ${({ disabled }) => disabled && css`
    opacity: .2;
    cursor: default;
  `}

  :hover{
    border: ${({ theme, disabled }) => !disabled && `2px solid ${theme.color.primary}`};
    &>div>svg{
      color: ${({ theme, disabled }) => !disabled && theme.color.primary};;
    }
  }
`;

const Pagination = ({
  allBackDisabled, allFrwDisabled, oneBackDisabled, oneFrwDisabled, elementsQtyOptions = [], page, allPages, changePage, changeElementsQty,
}) => (
  <StyledPaginationContainer>
    <StyledForm>
      <label
        htmlFor="lementsQty"
      >
        ilość postów na stronie:
        <StyledSelect
          id="lementsQty"
          onChange={(e) => changeElementsQty(e)}
        >
          {elementsQtyOptions.map((option) => (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          ))}
        </StyledSelect>
      </label>
    </StyledForm>
    <StyledPageInfo>
      strona:
      {' '}
      {page}
      {' '}
      z
      {' '}
      {allPages}
    </StyledPageInfo>
    <StyledButtons>
      <StyledPaginationButton
        disabled={allBackDisabled}
        onClick={() => changePage('first')}
      >
        <ReactSVG
          src={firstPage}
        />
      </StyledPaginationButton>
      <StyledPaginationButton
        disabled={oneBackDisabled}
        onClick={() => changePage(-1)}
      >
        <ReactSVG
          src={backPage}
        />
      </StyledPaginationButton>
      <StyledPaginationButton
        $rotate
        disabled={oneFrwDisabled}
        onClick={() => changePage(1)}
      >
        <ReactSVG
          src={backPage}
        />
      </StyledPaginationButton>
      <StyledPaginationButton
        $rotate
        disabled={allFrwDisabled}
        onClick={() => changePage('last')}
      >
        <ReactSVG
          src={firstPage}
        />
      </StyledPaginationButton>
    </StyledButtons>
  </StyledPaginationContainer>
);

Pagination.propTypes = {
  allBackDisabled: PropTypes.bool.isRequired,
  allFrwDisabled: PropTypes.bool.isRequired,
  oneBackDisabled: PropTypes.bool.isRequired,
  oneFrwDisabled: PropTypes.bool.isRequired,
  elementsQtyOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  page: PropTypes.number.isRequired,
  allPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
  changeElementsQty: PropTypes.func.isRequired,
};

export default Pagination;
