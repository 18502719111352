import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Section from 'templates/Section';
import mainTheme from 'themes/mainTheme';
import SectionTitle from 'components/atoms/SectionTitle';
import FigureItem from 'components/molecules/FigureItem';
import breakpoints from 'breakpoints';

const StyledFiguresContainer = styled.ul`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 10rem;

  @media screen and (min-width: ${breakpoints.L}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Figures = ({
  title, figures, bckImages, bckBlendHex,
}) => (
  <Section
    bckcolor={mainTheme.color.white}
    bckimage={bckImages}
    blend={[mainTheme.color.white, bckBlendHex]}
  >
    <SectionTitle
      arrowlength={title?.arrowLength}
    >
      {title?.title}
    </SectionTitle>
    <StyledFiguresContainer>
      {figures.map(({
        id, figureHeading, figureFigure, figureDescription,
      }) => (
        <FigureItem
          key={id}
          figureHeading={figureHeading}
          figureFigure={figureFigure}
          figureDescription={figureDescription}
        />
      ))}
    </StyledFiguresContainer>
  </Section>
);

Figures.propTypes = {
  figures: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    ),
  ).isRequired,
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  bckImages: PropTypes.arrayOf(PropTypes.object),
  bckBlendHex: PropTypes.string,
};

Figures.defaultProps = {
  bckImages: [],
  bckBlendHex: null,
};

const mapStateToProps = ({
  data: {
    figuresSection: {
      title = {}, figures = [], bckImages, bckBlendHex,
    } = {},
  },
}) => ({
  title,
  figures,
  bckImages,
  bckBlendHex,
});

export default connect(mapStateToProps)(Figures);
