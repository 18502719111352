import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import DesktopOfferBoxes from 'components/molecules/DesktopOfferBoxes';
import MobileOfferBoxes from 'components/molecules/MobileOfferBoxes';
import breakpoints from 'breakpoints';
import mainTheme from 'themes/mainTheme';

const StyledOfferBoxesMobile = styled(MobileOfferBoxes)`
  display: grid;
  
  @media screen and (min-width: ${breakpoints.M}) {
    display: none;
  }
`;

const StyledOfferBoxesDesktop = styled(DesktopOfferBoxes)`
  display: none;
  
  @media screen and (min-width: ${breakpoints.M}) {
    display: grid;
  }
`;

const Offer = ({ title }) => (
  <Section
    dots
  >
    <SectionTitle
      bckcolor={mainTheme.color.white}
      arrowlength={title?.arrowLength}
    >
      {title?.title}
    </SectionTitle>
    <StyledOfferBoxesDesktop />
    <StyledOfferBoxesMobile />
  </Section>
);

Offer.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
};

const mapStateToProps = ({
  data: {
    offerSection: {
      title = {},
    } = {},
  },
}) => ({
  title,
});

export default connect(mapStateToProps)(Offer);
