import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import breakpoints from 'breakpoints';

const StyledLinkWrapper = styled.div`
  position: relative;
  height: 40px;
`;

const StyledLink = styled(Link)`
  position: absolute;
  padding: 0 25px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.color.cta};
  text-decoration: none;
  transform: translate(-20px, 0);
  transition: transform .25s;
  ::before{
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(0, -50%) scale(0);
    width: 15px;
    height: 1px;
    left: 0;
    background-color: ${({ theme }) => theme.color.cta};
    transform-origin: right;
    transition: transform .25s;
  }
  :hover{
    transform: translate(0, 0);
    ::before{
      transform: translate(0, -50%) scale(1);
    }
    >div{
      transform: translate(0, -50%) scaleX(1);
      ::before{
        transform: translateY(0) rotate(0) scaleX(1);
        opacity: 0;
      }
      ::after{
        transform: translateY(0) rotate(0) scaleX(1);
        opacity: 0;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

const StyledArrow = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%) scaleX(2);
    width: 15px;
    height: 1px;
    right: 0;
    background-color: ${({ theme }) => theme.color.cta};
    transform-origin: left;
    transition: transform .25s;
    ::before, ::after{
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 6px;
      height: 1px;
      background-color: ${({ theme }) => theme.color.cta};
      transform-origin: right;
      transition: transform .25s, opacity .1s;
      transform: translateY(calc(-100% + 1px)) rotate(60deg) scaleX(1);
      opacity: 1;
    }
  ::after{
    transform: translateY(calc(100% - 1px)) rotate(-60deg) scaleX(1);
  }
`;

const Linkto = ({ children, to, className }) => (
  <StyledLinkWrapper
    className={className}
  >
    <StyledLink
      to={to}
    >
      {children}
      <StyledArrow />
    </StyledLink>
  </StyledLinkWrapper>
);

Linkto.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Linkto.defaultProps = {
  children: null,
  className: null,
};

export default Linkto;
