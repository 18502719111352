import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';

const StyledHeadingBox = styled.div`
  position: absolute;
  transform: translateY(calc(-100% - 1rem));
  z-index: 0;
  width: 80%;
  padding: ${({ $cardTypeRight }) => ($cardTypeRight ? '2rem 0 3rem 4rem' : '2rem 4rem 3rem 0')};
  margin-left: ${({ $cardTypeRight }) => ($cardTypeRight ? 'auto' : '-1rem')};
  margin-right: ${({ $cardTypeRight }) => ($cardTypeRight ? '0' : 'auto')};
  right: ${({ $cardTypeRight }) => $cardTypeRight && 0};
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  ::before{
    position: absolute;
    z-index: -1;
    content: '';
    top: 0;
    right: ${({ $cardTypeRight }) => ($cardTypeRight ? 'auto' : 0)};
    left: ${({ $cardTypeRight }) => ($cardTypeRight ? 0 : 'auto')};
    height: 100%;
    width: 100vw;
    background-color: ${({ theme }) => theme.color.primary};
    border: 1px solid #FFFFFF55;
    border-radius: 3px;
    opacity: .7;
  }

  @media screen and (min-width: ${breakpoints.S}) {
    padding: ${({ $cardTypeRight }) => ($cardTypeRight ? '2.5rem 0 3.5rem 4rem' : '2.5rem 4rem 3.5rem 0')};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    position: relative;
    transform: translateY(0);
    width: 80%;
    margin-bottom: -4rem;
    margin-left: ${({ $cardTypeRight }) => ($cardTypeRight ? 'auto' : '-5rem')};
    margin-right: ${({ $cardTypeRight }) => ($cardTypeRight ? '-5rem' : 'auto')};
    padding: ${({ $cardTypeRight }) => ($cardTypeRight ? '3rem 0 7rem 10rem' : '3rem 10rem 7rem 0')};
    ::before{
      position: absolute;
      z-index: -1;
      content: '';
      top: 0;
      right: ${({ $cardTypeRight }) => ($cardTypeRight ? 'auto' : 0)};
      left: ${({ $cardTypeRight }) => ($cardTypeRight ? 0 : 'auto')};
      height: 100%;
      width: 100vw;
      background-color: ${({ theme }) => theme.color.primary};
      border: 0px solid #FFFFFF55;
      border-radius: 0;
      opacity: 1;
    }
  }
`;

const StyledSubHeading = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.black};

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.xs : theme.fontSize.s)};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.s : theme.fontSize.m)};
  }
`;

const StyledMainHeading = styled.h4`
  margin-top: 2rem;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.s : theme.fontSize.m)};
  font-weight: ${({ theme }) => theme.fontWeight.black};

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.s : theme.fontSize.l)};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.m : theme.fontSize.xl)};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    font-size: ${({ $titleSmall, theme }) => ($titleSmall ? theme.fontSize.l : theme.fontSize.xxl)};
  }
`;

const CardHeading = ({
  cardSubtitle, cardTitle, cardTypeRight, titleSmall, className, titlecomponent,
}) => (
  <StyledHeadingBox
    $cardTypeRight={cardTypeRight}
    className={className}
  >
    <StyledSubHeading
      $titleSmall={titleSmall}
    >
      {cardSubtitle}
    </StyledSubHeading>
    <StyledMainHeading
      as={titlecomponent}
      $titleSmall={titleSmall}
    >
      {cardTitle}
    </StyledMainHeading>
  </StyledHeadingBox>
);

CardHeading.propTypes = {
  cardSubtitle: PropTypes.string,
  cardTitle: PropTypes.string,
  cardTypeRight: PropTypes.bool,
  titleSmall: PropTypes.bool,
  className: PropTypes.string,
  titlecomponent: PropTypes.string,
};

CardHeading.defaultProps = {
  cardSubtitle: null,
  cardTitle: null,
  cardTypeRight: false,
  titleSmall: false,
  className: null,
  titlecomponent: null,
};

export default CardHeading;
