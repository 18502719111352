import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'templates/PageHelmet';
import Linkto from 'components/atoms/Linkto';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import CardNoHeading from 'components/molecules/CardNoHeading';
import Accordion from 'components/organisms/Accordion';
import { routes } from 'routes';
import { useFetchView } from 'hooks/useFetchView';
import { fetchOfferView } from 'actions';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const StyledLinkto = styled(Linkto)`
  margin-top: 5rem;
`;

const OfferPage = ({ title, cards, dots }) => {
  const isFetched = useFetchView(fetchOfferView, 'offer');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="Sprzedajemy i doradzamy | Bluechip - nasza oferta"
        description="Oferujemy tonery, atramenty, bębny, tusze, głowice do drukarek i urządzeń, usługi doradcze i nie tylko... Sprzedajemy do biur, domów i urzędów."
      />
      {isFetched ? (
        <StyledSection
          dots={dots}
        >
          <SectionTitle
            component="h1"
            bckcolor={mainTheme.color.white}
            arrowlength={title?.arrowLength}
          >
            {title?.title}
          </SectionTitle>
          {cards.map((card, index) => (
            <React.Fragment
              key={card.cardInsideTitle + index}
            >
              <CardNoHeading
                id={card.id}
                cardInsideTitle={card.cardInsideTitle}
                cardParagraphs={card.cardParagraphs}
                cardImage={card.cardImage}
                cardTypeRight={card.cardTypeRight}
                cardBck={card.cardBck}
                quotationAbove={card.quotationAbove}
                textItalic={card.textItalic}
                link={card.link}
              />
              {card.accordionBelow
        && (
        <Accordion
          data={card.accordionBelow}
        />
        )}
            </React.Fragment>
          ))}
          <StyledLinkto
            to={routes.contact}
          >
            skontaktuj się z nami
          </StyledLinkto>
        </StyledSection>
      ) : (
        <Spinner />
      )}

    </>
  );
};

OfferPage.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  dots: PropTypes.bool,
};

OfferPage.defaultProps = {
  dots: false,
};

const mapStateToProps = ({ data: { offerView: { title = {}, cards = [], dots } = {} } }) => ({
  title,
  cards,
  dots,
});

export default connect(mapStateToProps)(OfferPage);
