import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardHeading from 'components/atoms/CardHeading';
import CardContent from 'components/atoms/CardContent';
import breakpoints from 'breakpoints';

const StyledCardWrapper = styled.div`
  position: relative;
  margin-bottom: 10rem;
  z-index: 0;
  :last-child{
    margin-bottom: 0rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin-bottom: 15rem;
  }
`;

const Card = ({
  cardSubtitle, cardTitle, cardParagraphs, cardImage, cardTypeRight, link, titleSmall, path, id, component, titlecomponent,
}) => (
  <StyledCardWrapper
    as={component}
  >
    <CardContent
      id={id}
      path={path}
      cardParagraphs={cardParagraphs}
      cardImage={cardImage}
      cardTypeRight={cardTypeRight}
      link={link}
      cardTitle={cardTitle}
    >
      <CardHeading
        titlecomponent={titlecomponent}
        titleSmall={titleSmall}
        cardSubtitle={cardSubtitle}
        cardTitle={cardTitle}
        cardTypeRight={cardTypeRight}
      />
    </CardContent>
  </StyledCardWrapper>
);

Card.propTypes = {
  cardSubtitle: PropTypes.string,
  cardTitle: PropTypes.string,
  cardParagraphs: PropTypes.string,
  cardImage: PropTypes.arrayOf(PropTypes.object),
  cardTypeRight: PropTypes.bool,
  titleSmall: PropTypes.bool,
  link: PropTypes.objectOf(PropTypes.string),
  path: PropTypes.string,
  id: PropTypes.string.isRequired,
  component: PropTypes.string,
  titlecomponent: PropTypes.string,
};

Card.defaultProps = {
  cardSubtitle: null,
  cardTitle: null,
  cardParagraphs: null,
  cardImage: null,
  cardTypeRight: false,
  titleSmall: false,
  link: null,
  path: null,
  component: 'div',
  titlecomponent: 'h4',
};

export default Card;
