import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import Section from 'templates/Section';
import CardHeading from 'components/atoms/CardHeading';
import Linkto from 'components/atoms/Linkto';
import { routes } from 'routes';
import { fetchArticleBlogView } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const StyledCardHeading = styled(CardHeading)`
  position: relative;
  top: 0;
  transform: translateY(0);
  margin-left: 0;
  margin-bottom: 1rem;
  ::before{
    opacity: 1;
  }

  @media screen and (min-width: ${breakpoints.S}) {
    margin-bottom: 3rem;
  }
`;

const StyledSubtitle = styled.h2`
  margin: 1rem 0 3rem;
  font-size: ${({ theme }) => theme.fontSize.s};

  @media screen and (min-width: ${breakpoints.M}) {
    margin: 1rem 0 4rem;
    font-size: ${({ theme }) => theme.fontSize.m};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    margin: 1rem 0 6rem;
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const StyledMainImg = styled.img`
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 2rem;

  @media screen and (min-width: ${breakpoints.L}) {
    margin: 0 auto 4rem;
  }
`;

const StyledAuthor = styled.p`
  text-align: right;
  margin-right: 2rem;
  font-style: italic;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const StyledBlockContainer = styled.div`
  margin: 2rem 0 4rem;
  
  @media screen and (min-width: ${breakpoints.L}) {
    margin: 2rem auto 5rem;
  }
`;

const StyledBlockTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: 1.5rem;
  font-size: ${({ theme }) => theme.fontSize.s};

@media screen and (min-width: ${breakpoints.M}) {
  margin-bottom: 2.5rem;
  font-size: ${({ theme }) => theme.fontSize.m};
}

@media screen and (min-width: ${breakpoints.XL}) {
  font-size: ${({ theme }) => theme.fontSize.l};
}
`;

const StyledTextContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: ${({ $blockImgInvert }) => ($blockImgInvert ? 'column-reverse' : 'column')};
  align-items: center;
  
  @media screen and (min-width: ${breakpoints.M}) {
    padding: 0 2rem;
    padding-left: 3rem;
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    flex-direction: ${({ $blockImgInvert }) => ($blockImgInvert ? 'row-reverse' : 'row')};
  }
`;

const StyledBlockText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  text-align: justify;
  line-height: 1.65;

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const StyledSmallImg = styled.img`
  width: 100%;
  max-width: ${({ $blockImgSmall }) => ($blockImgSmall ? '200px' : '400px')};
  margin: ${({ $blockImgInvert }) => ($blockImgInvert ? '0 0 1rem 0' : '1rem 0 0 0')};
    
  @media screen and (min-width: ${breakpoints.M}) {
    max-width: ${({ $blockImgSmall }) => ($blockImgSmall ? '250px' : '500px')};
  }
    
  @media screen and (min-width: ${breakpoints.L}) {
    width: 50%;
    margin: ${({ $blockImgInvert }) => ($blockImgInvert ? '0 2rem 0 0' : '0 0 0 2rem')};
  }
`;

const BlogArticle = ({
  match: { params: { id } },
  dots,
  blogText: {
    mainTitle, subtitle, date, author, mainImg, mainImgAlt, textBlocks = [],
  } = {},
  blogText = null,
}) => {
  const isFetched = useFetchView(fetchArticleBlogView, id, id);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      {blogText ? (
        <>
          {isFetched ? (
            <StyledSection
              dots={dots}
            >
              <StyledCardHeading
                titleSmall
                titlecomponent="h1"
                cardSubtitle={date}
                cardTitle={mainTitle}
                cardTypeRight={false}
              />
              <StyledSubtitle>{subtitle}</StyledSubtitle>
              <StyledMainImg
                src={mainImg?.url}
                alt={mainImgAlt}
              />
              {textBlocks.length && textBlocks.map((block) => (
                <StyledBlockContainer
                  key={block.id}
                >
                  {block.blockTitle && (
                  <StyledBlockTitle>
                    {block.blockTitle}
                  </StyledBlockTitle>
                  )}
                  <StyledTextContainer
                    $blockImgInvert={block.blockImgInvert}
                  >
                    {block.blockTxt && (
                    <StyledBlockText>
                      {block.blockTxt}
                    </StyledBlockText>
                    )}
                    {block.blockImg && (
                    <StyledSmallImg
                      src={block.blockImg?.url}
                      alt={block.blockImgAlt}
                      $blockImgInvert={block.blockImgInvert}
                      $blockImgSmall={block.blockImgSmall}
                    />
                    )}
                  </StyledTextContainer>
                </StyledBlockContainer>
              ))}
              <StyledAuthor>
                {author}
              </StyledAuthor>
              <Linkto
                to={routes.blog}
              >
                czy wiesz...
              </Linkto>
            </StyledSection>
          ) : (
            <Spinner />
          )}
        </>
      ) : (
        <Redirect to={routes.blog} />
      )}
      }
    </>
  );
};

BlogArticle.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  dots: PropTypes.bool,
  blogText: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object,
    ]).isRequired,
  ),
};

BlogArticle.defaultProps = {
  dots: false,
};

const mapStateToProps = ({ data: { blogView: { cards = [], dots } = {} } }, ownProps) => {
  const { match: { params: { id } } } = ownProps;
  return {
    blogText: cards.filter((card) => card.id === id)[0]?.blogText || {},
    dots,
  };
};

export default connect(mapStateToProps)(BlogArticle);
