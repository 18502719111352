import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';
import Glass from 'components/atoms/Glass';
import quot from 'assets/icons/quot.svg';

const StyledGlass = styled(Glass)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
  margin-bottom: 7rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    width: 90%;
    font-size: ${({ theme }) => theme.fontSize.s};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin-bottom: 3rem;
    width: 60%;
    font-size: ${({ theme }) => theme.fontSize.m};
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;

const StyledQuotationTxtBox = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-style: italic;
  
  @media screen and (min-width: ${breakpoints.S}) {
    margin: 0;
  }
`;

const StyledQuotationAuthor = styled.footer`
  margin-top: 2rem;
  margin-right: 5rem;
  padding: .4rem 3rem;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

const StyledQuotationTxt = styled.p`
  width: 70%;
  padding: 1rem 0;
`;

const StyledQuotationImg = styled.div`
  width: 18%;
  border-radius: 50%;
  margin-left: 2rem;
  height: 0;
  padding: 9% 0;
  background-image: ${({ img }) => img && `url(${img?.url})`};
  background-position: center;
  background-size: cover;

`;

const StyledIcon = styled(ReactSVG)`
  width: 7%;
  align-self: flex-start;
  margin-right: 1rem;
  >svg{
    width: 3rem;
    height: 3rem;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    margin-right: 2rem;
    svg{
      width: 6rem;
      height: 6rem;
    }
  }
`;

const Quotation = ({ text, img, author }) => (
  <StyledGlass
    component="article"
    size={6}
    round
  >
    <StyledQuotationTxtBox>
      <StyledIcon
        src={quot}
      />
      <StyledQuotationTxt>
        {text}
      </StyledQuotationTxt>
      <StyledQuotationImg
        img={img}
      />
    </StyledQuotationTxtBox>
    <StyledQuotationAuthor>
      <p>
        {author}
      </p>
    </StyledQuotationAuthor>
  </StyledGlass>
);

Quotation.propTypes = {
  text: PropTypes.string.isRequired,
  img: PropTypes.object,
  author: PropTypes.string.isRequired,
};

Quotation.defaultProps = {
  img: null,
};

export default Quotation;
