import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';

const StyledAccordionDescription = styled.div`
  display: ${({ $active }) => ($active ? 'block' : 'none')};
  position: relative;
  overflow: hidden;
`;

const StyledBox = styled.div`
  position: relative;
  margin: 1rem 1.5rem 3rem;
  ::before{
    position: absolute;
    content: '';
    left: -1.3rem;
    top: 0;
    height: 100%;
    width: 8px;
    background-color: ${({ theme }) => theme.color.secondaryLigt};
  }

  @media screen and (min-width: ${breakpoints.S}) {
    margin: 2rem 3rem 4rem;
    ::before{
      left: -2rem;
    }
  }

  @media screen and (min-width: ${breakpoints.M}) {
    margin: 3rem 5rem 6rem;
    ::before{
    left: -2.5rem;
    }
  }
`;

const StyledAccordionParagraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.65;
  font-size: ${({ theme }) => theme.fontSize.xxs};

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const AccordionDescription = ({ active, description }) => (
  <StyledAccordionDescription
    $active={active}
  >
    <StyledBox>
      {description.split('|').map((par, index) => (
        <StyledAccordionParagraph
          key={index}
        >
          {par}
        </StyledAccordionParagraph>
      ))}
    </StyledBox>
  </StyledAccordionDescription>
);

AccordionDescription.propTypes = {
  active: PropTypes.bool,
  description: PropTypes.string.isRequired,
};

AccordionDescription.defaultProps = {
  active: false,
};

export default AccordionDescription;
