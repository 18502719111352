import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Section from 'templates/Section';
import mainTheme from 'themes/mainTheme';
import SerifParagraph from 'components/atoms/SerifParagraph';

const FullWidthParagraph = ({
  className, text = '', bckImages, bckBlendHex, bckAttachement, component,
}) => (
  <Section
    component={component}
    className={className}
    bckcolor={mainTheme.color.primary}
    bckimage={bckImages}
    blend={[mainTheme.color.primary, bckBlendHex]}
    attachement={bckAttachement}
  >
    <SerifParagraph>
      {text}
    </SerifParagraph>
  </Section>
);

FullWidthParagraph.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string,
  bckImages: PropTypes.arrayOf(PropTypes.object),
  bckBlendHex: PropTypes.string,
  bckAttachement: PropTypes.string,
  component: PropTypes.string,
};

FullWidthParagraph.defaultProps = {
  text: '',
  bckImages: [],
  bckBlendHex: null,
  bckAttachement: 'scroll',
  component: 'section',
};

const mapStateToProps = ({
  data: {
    fullWidthParagraph: {
      text, bckImages, bckBlendHex, bckAttachement,
    } = {},
  },
}) => ({
  text,
  bckImages,
  bckBlendHex,
  bckAttachement,
});

export default connect(mapStateToProps)(FullWidthParagraph);
