import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay, Navigation, Pagination, EffectCoverflow,
} from 'swiper';
import 'swiper/swiper-bundle.css';
import 'components/molecules/Swipers/swiper.sass';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';
import circles from 'assets/icons/circles.svg';
import NewsInnerSlide from 'components/molecules/Swipers/NewsInnerSlide';
import { routes } from 'routes';

const StyledSliderContainer = styled.div`
  position: relative;
  z-index: 0;

  :hover{
    .slider-bar{
      transition: transform .2s;
      transform: translateY(0);

    }
    .slider-description{
      transform: translateY(0);

    }
    .slider-title{
      transform: translateY(0);
    }
  }
`;

const StyledSliderBck = styled(ReactSVG)`
    position: absolute;
    top: -10rem;
    right: -15rem;
    color: ${({ theme }) => theme.color.secondaryLigt};;
    >svg{
      width: 300px;
    }

    @media screen and (min-width: ${breakpoints.S}) {
      top: -7rem;
      right: -18rem;
      >svg{
        width: 100%;
      }
    }
`;

const NewsSwiper = ({ slides }) => {
  SwiperCore.use([Autoplay, Navigation, Pagination, EffectCoverflow]);

  return (
    <StyledSliderContainer>
      <Swiper
        wrapperTag="ul"
        id="news-carousel"
        loop="true"
        autoplay={{
          delay: 2500,
        }}
        navigation
        pagination={
        {
          clickable: true,
        }
      }
        effect="coverflow"
      >
        {slides?.map(({
          title, description, image, link,
        }, index) => (
          <SwiperSlide
            tag="li"
            key={index}
            style={{ listStyle: 'none' }}
          >
            <Link
              to={routes[link]}
            >
              <NewsInnerSlide
                title={title}
                description={description}
                image={image}
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <StyledSliderBck
        src={circles}
      />
    </StyledSliderContainer>
  );
};

NewsSwiper.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
      ]),
    ),
  ).isRequired,
};

export default NewsSwiper;
