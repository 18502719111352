import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledMain = styled.main`
    position: relative;
    z-index: 0;
  `;

const StyledMainWrapper = ({ children }) => (
  <StyledMain
    id="main"
  >
    {children}
  </StyledMain>
);

StyledMainWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default StyledMainWrapper;
