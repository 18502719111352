import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'templates/PageHelmet';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import Card from 'components/molecules/Card';
import Pagination from 'components/molecules/Pagination';
import usePagination from 'hooks/usePagination';
import { fetchBlogView } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const Blog = ({
  title, dots, match: { path },
}) => {
  const isFetched = useFetchView(fetchBlogView, 'blog');
  const {
    displaiedCard, allBackDisabled, allFrwDisabled, oneBackDisabled, oneFrwDisabled, elementsQtyOptions = [], page, allPages, changePage, changeElementsQty,
  } = usePagination();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="Ciekawoski o drukowaniu, atramentach i tonerach | Bluechip - blog"
        description="Czy wiesz, jak zaoszczędzić na drukowaniu? Czy wiesz, jaką drukarkę wybrać? Czy wiesz jaki toner i jaki tusz jest odpowiedni? Sprawdź!"
      />
      {isFetched ? (
        <StyledSection
          dots={dots}
        >
          <SectionTitle
            component="h1"
            bckcolor={mainTheme.color.white}
            arrowlength={title?.arrowLength}
          >
            {title?.title}
          </SectionTitle>
          {displaiedCard.map((card, index) => (
            <Card
              component="article"
              titlecomponent="h2"
              path={path}
              key={card.id}
              id={card.id}
              cardSubtitle={card.cardSubtitle}
              titleSmall={card.titleSmall}
              cardTitle={card.cardTitle}
              cardParagraphs={card.cardParagraphs}
              cardImage={card.cardImage}
              cardTypeRight={!!((index % 2))}
              link={card.link}
            />
          ))}
          <Pagination
            allBackDisabled={allBackDisabled}
            allFrwDisabled={allFrwDisabled}
            oneBackDisabled={oneBackDisabled}
            oneFrwDisabled={oneFrwDisabled}
            elementsQtyOptions={elementsQtyOptions}
            page={page}
            allPages={allPages}
            changePage={changePage}
            changeElementsQty={changeElementsQty}
          />
        </StyledSection>
      ) : (
        <Spinner />
      )}

    </>
  );
};

Blog.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  path: PropTypes.string,
  dots: PropTypes.bool,
};

Blog.defaultProps = {
  dots: false,
  path: null,
};

const mapStateToProps = ({ data: { blogView: { title = {}, dots } = {} } }) => ({
  title,
  dots,
});

export default connect(mapStateToProps)(Blog);
