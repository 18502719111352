import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'templates/PageHelmet';
import Linkto from 'components/atoms/Linkto';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import CardNoHeading from 'components/molecules/CardNoHeading';
import { routes } from 'routes';
import { fetchAboutView } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const About = ({
  title, cards, dots,
}) => {
  const isFetched = useFetchView(fetchAboutView, 'about');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="Bluechip to 15 lat doświadczeń i kontaktów | Bluechip - o firmie"
        description="Dbamy o najwyższą jakość naszych usług, które oferujemy w obrębie druku i IT. Założyciele Bluechip są obecni na rynku IT oraz druku od ponad 15 lat."
      />
      {isFetched ? (
        <StyledSection
          dots={dots}
        >
          <SectionTitle
            component="h1"
            bckcolor={mainTheme.color.white}
            arrowlength={title?.arrowLength}
          >
            {title?.title}
          </SectionTitle>
          {cards.map((card, index) => (
            <CardNoHeading
              id={card.id}
              key={index}
              cardInsideTitle={card.cardInsideTitle}
              cardParagraphs={card.cardParagraphs}
              cardImage={card.cardImage}
              cardTypeRight={card.cardTypeRight}
              cardBck={card.cardBck}
              quotationAbove={card.quotationAbove}
              textItalic={card.textItalic}
            />
          ))}
          <Linkto
            to={routes.offer}
          >
            nasza oferta
          </Linkto>
        </StyledSection>
      ) : (
        <Spinner />
      )}

    </>
  );
};

About.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  dots: PropTypes.bool,
};

About.defaultProps = {
  dots: false,
};

const mapStateToProps = ({ data: { aboutView: { title = {}, cards = [], dots } = {}, dataFetched = {} } = {} }) => ({
  title,
  cards,
  dots,
  dataFetched,
});

export default connect(mapStateToProps)(About);
