import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'templates/PageHelmet';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import CardNoHeading from 'components/molecules/CardNoHeading';
import { fetchShopView } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const Shop = ({ cards, dots, title = {} }) => {
  const isFetched = useFetchView(fetchShopView, 'shop');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="sklep on-line | Bluechip - kontakt"
        description="Kup tonery i atramenty do drukarek w przystępnych cenach oraz sprzęt do druku i IT uznanych producentów."
      />
      {isFetched ? (
        <StyledSection
          dots={dots}
        >
          <SectionTitle
            component="h1"
            bckcolor={mainTheme.color.white}
            arrowlength={title?.arrowLength}
          >
            {title?.title}
          </SectionTitle>
          {cards.map((card) => (
            <CardNoHeading
              key={card.id}
              id={card.id}
              cardInsideTitle={card.cardInsideTitle}
              cardParagraphs={card.cardParagraphs}
              cardImage={card.cardImage}
              cardTypeRight={card.cardTypeRight}
              cardBck={card.cardBck}
              quotationAbove={card.quotationAbove}
              paragraphsIcons={card.paragraphsIcons}
            />
          ))}
        </StyledSection>
      ) : (
        <Spinner />
      )}

    </>
  );
};

Shop.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  dots: PropTypes.bool,
};

Shop.defaultProps = {
  dots: false,
};

const mapStateToProps = ({ data: { shopView: { title = {}, cards = [], dots } = {} } }) => ({
  title,
  cards,
  dots,
});

export default connect(mapStateToProps)(Shop);
