import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledContainer = styled.header`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const FullHeightContainer = ({ children, className }) => (
  <StyledContainer className={className}>
    {children}
  </StyledContainer>
);

FullHeightContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

FullHeightContainer.defaultProps = {
  className: null,
};

export default FullHeightContainer;
