import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import debounce from 'lodash/debounce';
import Wrapper from 'templates/Wrapper';
import logoBig from 'assets/logos/logoBig.svg';
import basketIcon from 'assets/icons/basket.svg';
import breakpoints from 'breakpoints';
import { routes } from 'routes';

const StyledLogo = styled(ReactSVG)`
  &>svg{
    width: ${({ $isMenuMobile }) => ($isMenuMobile ? '70%' : '100%')};
    height: ${({ $isMenuMobile }) => ($isMenuMobile ? '70%' : '100%')};
  }
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledNav = styled.nav`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 76px;
  padding-top: ${({ $isMenuMobile }) => ($isMenuMobile ? '1rem' : '0')};
  ::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: ${({ theme }) => `0 0 25px -13px ${theme.color.secondary}`};
    z-index: -1;
    transform: ${({ scrolled }) => (scrolled ? 'translateY(0)' : 'translateY(-150%)')};
    transition: transform .3s;
  }
  ::after, .bottom-bck{
    opacity: 0;
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    right: 0;
    border-radius: 50%;
    z-index: -1;
    background-color: ${({ theme }) => theme.color.primaryLight};
    transition: transform .3s;
  }
  .bottom-bck{
    background-color: ${({ theme }) => theme.color.secondaryLigt};
    top: 100vh;
    right: auto;
    left: 0;
  }
  ${({ $isMenuOpen }) => $isMenuOpen && css`
    ::after, .bottom-bck{
      opacity: 1;
      transform: scale(250);
    }
  `}
`;

const StyledList = styled.ul`
  list-style: none;
  display: ${({ $isMenuMobile }) => ($isMenuMobile ? 'none' : 'flex')};
  ${({ $isMenuOpen }) => $isMenuOpen && css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98vh;
  `}
`;

const StyledListItem = styled.li`
  margin-left: 1rem;
`;

const StyledNavLink = styled(NavLink)`
  position: relative;
  display: block;
  padding: 1rem 3rem;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.standard};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.secondary};
  ${({ $isMenuMobile }) => $isMenuMobile && css`
    font-family: ${({ theme }) => theme.fontFamily.serif};
    font-size: ${({ theme }) => theme.fontSize.l};
    ::before {
      display: none;
    }
  `}

  &:hover, &.active{
    color: ${({ theme }) => theme.color.primary};
  }

  &::before {
    opacity: 0;
    content: '';
    position: absolute;
    top: calc(100% - 8px);
    left: 50%;
    height: 1px;
    width: 60%;
    background-color: ${({ theme }) => theme.color.primary};
    transform-origin: 50% 0%;
    transform: translate3d(-50%, 3px, 0);
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
    transition-delay: 0.1s;
}

:hover::before {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
    transition-delay: 0s;
}

`;

const StyledBasketIcon = styled(ReactSVG)`
  color: ${({ theme }) => theme.color.secondary};
`;

const StyledLink = styled(NavLink)`
  display: none;

  :hover, &.active{
    ${StyledBasketIcon} {
    color: ${({ theme }) => theme.color.primary};
    }
  }

  @media screen and (min-width: 1500px){
    display: block;
    position: absolute;
    left: 55%;
    transform: translateX(calc(-100% - 3rem));
    }
`;

const StyledBurger = styled.button`
  display: ${({ $isMenuMobile }) => ($isMenuMobile ? 'block' : 'none')};
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: none;
  border: ${({ theme }) => `2px solid ${theme.color.secondary}`};
  flex-shrink: 0;
  cursor: pointer;
    ::after, ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 70%;
      height: 2px;
      background-color: ${({ theme }) => theme.color.secondary};
      transition: transform .15s;
    }
    ::before {
      transform: translate(-50%, -5px);
    }
    ::after {
      transform: translate(-50%, 5px);
    }
    ${({ $isMenuOpen }) => !$isMenuOpen && css`
      :hover{
        &::before{
          transform: translate(-50%, 5px);
        }
        &::after{
          transform: translate(-50%, -5px);
        }
      }
    `}
    ${({ $isMenuOpen }) => $isMenuOpen && css`
      ::before {
        transform: translate(-50%, 0) rotate(45deg);
      }
      ::after {
        transform: translate(-50%, 0) rotate(-45deg);
      }
    `}
`;

const StyledSpan = styled.span`
`;

const Navbar = ({ navigation }) => {
  const [isScrolled, setScrolled] = useState(false);
  const [isMenuMobile, setMenuMobile] = useState(true);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenuOpen = () => {
    if (isMenuOpen) setMenuOpen(false);
  };

  const checkWidth = () => {
    if (window.innerWidth <= breakpoints.L.split('px')[0]) setMenuMobile(true);
    else setMenuMobile(false);
  };

  useEffect(() => {
    const checkPossition = () => {
      if (window.scrollY >= 80) setScrolled(true);
      else setScrolled(false);
    };
    window.addEventListener('scroll', debounce(checkPossition, 150));

    return () => window.removeEventListener('scroll', debounce(checkPossition, 150));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', debounce(checkWidth, 250));

    return () => window.removeEventListener('resize', debounce(checkWidth, 250));
  }, []);

  return (
    <StyledNav
      scrolled={isScrolled}
      $isMenuMobile={isMenuMobile}
      $isMenuOpen={isMenuOpen}
      onClick={isMenuOpen ? toggleMenuOpen : null}
    >
      <StyledSpan
        className="bottom-bck"
        $isMenuOpen={isMenuOpen}
      />
      <StyledWrapper>
        <Link to={routes.home}>
          <StyledLogo
            src={logoBig}
            $isMenuMobile={isMenuMobile}
            afterInjection={checkWidth}
          />
        </Link>
        <StyledList
          $isMenuMobile={isMenuMobile}
          $isMenuOpen={isMenuOpen}
        >
          {Object.entries(routes).map(([name, route]) => (
            <React.Fragment
              key={route}
            >
              {navigation[name]
              && (
              <StyledListItem>
                <StyledNavLink
                  exact
                  to={route}
                  activeClassName="active"
                  $isMenuMobile={isMenuOpen}
                >
                  {navigation[name]}
                </StyledNavLink>
              </StyledListItem>
              )}
            </React.Fragment>
          )).slice(isMenuMobile ? 0 : 1)}
        </StyledList>
        <StyledBurger
          $isMenuMobile={isMenuMobile}
          $isMenuOpen={isMenuOpen}
          onClick={() => setMenuOpen(!isMenuOpen)}
        />
        <StyledLink
          to={routes.shop}
          activeClassName="active"
        >
          <StyledBasketIcon
            src={basketIcon}
          />
        </StyledLink>
      </StyledWrapper>
    </StyledNav>
  );
};

Navbar.propTypes = {
  navigation: PropTypes.objectOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ data: { navigation = {} } = {} }) => ({
  navigation,
});

export default connect(mapStateToProps)(Navbar);
