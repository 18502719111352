import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import BrandsSwiper from 'components/molecules/Swipers/BrandsSwiper';

const Brands = ({
  title, logos, bckImages, bckBlendHex, bckAttachement,
}) => (
  <Section
    bckcolor={mainTheme.color.primary}
    bckimage={bckImages}
    blend={[mainTheme.color.primary, bckBlendHex]}
    attachement={bckAttachement}
  >
    <SectionTitle
      arrowlength={title?.arrowLength}
      color={mainTheme.color.white}
    >
      {title?.title}
    </SectionTitle>
    <BrandsSwiper
      logos={logos}
    />
  </Section>
);

Brands.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  logos: PropTypes.arrayOf(PropTypes.object).isRequired,
  bckImages: PropTypes.arrayOf(PropTypes.object),
  bckBlendHex: PropTypes.string,
  bckAttachement: PropTypes.string,
};

Brands.defaultProps = {
  bckImages: [],
  bckAttachement: 'scroll',
  bckBlendHex: null,
};

const mapStateToProps = ({
  data: {
    brandsSection: {
      title = {}, logos = [], bckImages = [], bckBlendHex, bckAttachement,
    } = {},
  },
}) => ({
  title,
  logos,
  bckImages,
  bckBlendHex,
  bckAttachement,
});

export default connect(mapStateToProps)(Brands);
