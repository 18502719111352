export const blogArticleViewQueryFn = (id) => `{
  card(where: {id: "${id}"}) {
    id
    blogText {
      id
      mainTitle
      subtitle
      date
      author
      mainImg {
        url
      }
      mainImgAlt
      textBlocks {
        id
        blockTitle
        blockImg {
          url
        }
        blockImgAlt
        blockTxt
        blockImgInvert
        blockImgSmall
      }
    }
  }
}`;
