export const aboutViewQuery = `{
  sectionOrSubpages(
    where: {sectionName: "aboutView"}
  ) {
    sectionName
    bckImages {
      url
    }
    bckBlendHex
    bckAttachement
    dots
    sectionOrPageTitle {
      title
      arrowLength
    }
    cards {
      id
      cardTitle
      cardSubtitle
      cardParagraphs
      link {
        name
        to
      }
      cardImage {
        url
      }
      cardTypeRight
      cardInsideTitle
      cardBck
      textItalic
      quotationAbove {
        author
        text
        img {
          url
        }
      }
    }
  }
}`;
