import { fetchCmsData } from 'helpers/fetchCmsData';
import { sectionsQuery } from 'queries/sectionsQuery';
import { navHeaderQuery } from 'queries/navHeaderQuery';
import { footerQuery } from 'queries/footerQuery';
import { aboutViewQuery } from 'queries/aboutViewQuery';
import { contactViewQuery } from 'queries/contactViewQuery';
import { shopViewQuery } from 'queries/shopViewQuery';
import { offerViewQuery } from 'queries/offerViewQuery';
import { blogViewQuery } from 'queries/blogViewQuery';
import { blogArticleViewQueryFn } from 'queries/BlogArticleViewQueryFn';

export const FETCH_SECTIONS_REQUESTED = 'FETCH_SECTIONS_REQUESTED';
export const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
export const FETCH_SECTIONS_ERROR = 'FETCH_SECTIONS_ERROR';
export const FETCH_NAV_HEADER_REQUESTED = 'FETCH_NAV_HEADER_REQUESTED';
export const FETCH_NAV_HEADER_SUCCESS = 'FETCH_NAV_HEADER_SUCCESS';
export const FETCH_NAV_HEADER_ERROR = 'FETCH_NAV_HEADER_ERROR';
export const FETCH_FOOTER_REQUESTED = 'FETCH_FOOTER_REQUESTED';
export const FETCH_FOOTER_SUCCESS = 'FETCH_FOOTER_SUCCESS';
export const FETCH_FOOTER_ERROR = 'FETCH_FOOTER_ERROR';
export const FETCH_ABOUT_VIEW_REQUESTED = 'FETCH_ABOUT_VIEW_REQUESTED';
export const FETCH_ABOUT_VIEW_SUCCESS = 'FETCH_ABOUT_VIEW_SUCCESS';
export const FETCH_ABOUT_VIEW_ERROR = 'FETCH_ABOUT_VIEW_ERROR';
export const FETCH_CONTACT_VIEW_REQUESTED = 'FETCH_CONTACT_VIEW_REQUESTED';
export const FETCH_CONTACT_VIEW_SUCCESS = 'FETCH_CONTACT_VIEW_SUCCESS';
export const FETCH_CONTACT_VIEW_ERROR = 'FETCH_CONTACT_VIEW_ERROR';
export const FETCH_SHOP_VIEW_REQUESTED = 'FETCH_SHOP_VIEW_REQUESTED';
export const FETCH_SHOP_VIEW_SUCCESS = 'FETCH_SHOP_VIEW_SUCCESS';
export const FETCH_SHOP_VIEW_ERROR = 'FETCH_SHOP_VIEW_ERROR';
export const FETCH_OFFER_VIEW_REQUESTED = 'FETCH_OFFER_VIEW_REQUESTED';
export const FETCH_OFFER_VIEW_SUCCESS = 'FETCH_OFFER_VIEW_SUCCESS';
export const FETCH_OFFER_VIEW_ERROR = 'FETCH_OFFER_VIEW_ERROR';
export const FETCH_BLOG_VIEW_REQUESTED = 'FETCH_BLOG_VIEW_REQUESTED';
export const FETCH_BLOG_VIEW_SUCCESS = 'FETCH_BLOG_VIEW_SUCCESS';
export const FETCH_BLOG_VIEW_ERROR = 'FETCH_BLOG_VIEW_ERROR';
export const FETCH_ARTICLE_BLOG_VIEW_REQUESTED = 'FETCH_ARTICLE_BLOG_VIEW_REQUESTED';
export const FETCH_ARTICLE_BLOG_VIEW_SUCCESS = 'FETCH_ARTICLE_BLOG_VIEW_SUCCESS';
export const FETCH_ARTICLE_BLOG_VIEW_ERROR = 'FETCH_ARTICLE_BLOG_VIEW_ERROR';

export const fetchSections = () => async (dispatch) => {
  dispatch({
    type: FETCH_SECTIONS_REQUESTED,
    payload: { message: 'Fetching sections data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(sectionsQuery);
    dispatch({
      type: FETCH_SECTIONS_SUCCESS,
      payload: {
        message: 'Sections data fetch success!',
        data: sectionOrSubpages,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_SECTIONS_ERROR,
      error: err,
    });
  }
};

export const fetchNavHeader = () => async (dispatch) => {
  dispatch({
    type: FETCH_NAV_HEADER_REQUESTED,
    payload: { message: 'Fetching header & nav data...' },
  });
  try {
    const {
      data: {
        data: {
          navLinksDescriptions, headers, fullWidthParagraphs,
        } = {},
      } = {},
    } = await fetchCmsData(navHeaderQuery);
    dispatch({
      type: FETCH_NAV_HEADER_SUCCESS,
      payload: {
        message: 'Data neader & nav fetch success!',
        data: {
          navLinksDescriptions,
          headers,
          fullWidthParagraphs,
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_NAV_HEADER_ERROR,
      error: err,
    });
  }
};

export const fetchFooter = () => async (dispatch) => {
  dispatch({
    type: FETCH_FOOTER_REQUESTED,
    payload: { message: 'Fetching footer data...' },
  });
  try {
    const { data: { data: { footers } = {} } = {} } = await fetchCmsData(footerQuery);
    dispatch({
      type: FETCH_FOOTER_SUCCESS,
      payload: {
        message: 'Data footer fetch success!',
        data: {
          dataBoxLeft: footers?.[0]?.footerDataBoxesLeft,
          dataBoxRight: footers?.[0]?.footerDataBoxesRight,
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_FOOTER_ERROR,
      error: err,
    });
  }
};

export const fetchAboutView = () => async (dispatch) => {
  dispatch({
    type: FETCH_ABOUT_VIEW_REQUESTED,
    payload: { message: 'Fetching about view data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(aboutViewQuery);
    dispatch({
      type: FETCH_ABOUT_VIEW_SUCCESS,
      payload: {
        message: 'Data about view fetch success!',
        data: {
          aboutView: Object.assign(
            sectionOrSubpages[0],
            { title: sectionOrSubpages[0].sectionOrPageTitle },
          ),
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_ABOUT_VIEW_ERROR,
      error: err,
    });
  }
};

export const fetchContactView = () => async (dispatch) => {
  dispatch({
    type: FETCH_CONTACT_VIEW_REQUESTED,
    payload: { message: 'Fetching contact view data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(contactViewQuery);
    dispatch({
      type: FETCH_CONTACT_VIEW_SUCCESS,
      payload: {
        message: 'Data contact view fetch success!',
        data: {
          contactView: Object.assign(
            sectionOrSubpages[0],
            { title: sectionOrSubpages[0].sectionOrPageTitle },
          ),
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_CONTACT_VIEW_ERROR,
      error: err,
    });
  }
};

export const fetchShopView = () => async (dispatch) => {
  dispatch({
    type: FETCH_SHOP_VIEW_REQUESTED,
    payload: { message: 'Fetching shop view data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(shopViewQuery);
    dispatch({
      type: FETCH_SHOP_VIEW_SUCCESS,
      payload: {
        message: 'Data shop view fetch success!',
        data: {
          shopView: Object.assign(
            sectionOrSubpages[0],
            { title: sectionOrSubpages[0].sectionOrPageTitle },
          ),
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_SHOP_VIEW_ERROR,
      error: err,
    });
  }
};

export const fetchOfferView = () => async (dispatch) => {
  dispatch({
    type: FETCH_OFFER_VIEW_REQUESTED,
    payload: { message: 'Fetching offer view data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(offerViewQuery);
    dispatch({
      type: FETCH_OFFER_VIEW_SUCCESS,
      payload: {
        message: 'Data offer view fetch success!',
        data: {
          offerView: Object.assign(
            sectionOrSubpages[0],
            { title: sectionOrSubpages[0].sectionOrPageTitle },
          ),
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_OFFER_VIEW_ERROR,
      error: err,
    });
  }
};

export const fetchBlogView = () => async (dispatch) => {
  dispatch({
    type: FETCH_BLOG_VIEW_REQUESTED,
    payload: { message: 'Fetching blog view data...' },
  });
  try {
    const { data: { data: { sectionOrSubpages } = {} } = {} } = await fetchCmsData(blogViewQuery);
    dispatch({
      type: FETCH_BLOG_VIEW_SUCCESS,
      payload: {
        message: 'Data blog view fetch success!',
        data: {
          blogView: Object.assign(
            sectionOrSubpages[0],
            { title: sectionOrSubpages[0].sectionOrPageTitle },
          ),
        },
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_BLOG_VIEW_ERROR,
      error: err,
    });
  }
};

export const fetchArticleBlogView = (blogArticleId) => async (dispatch) => {
  dispatch({
    type: FETCH_ARTICLE_BLOG_VIEW_REQUESTED,
    payload: { message: 'Fetching article view data...' },
  });
  try {
    const { data: { data: { card } = {} } = {} } = await fetchCmsData(blogArticleViewQueryFn(blogArticleId));
    dispatch({
      type: FETCH_ARTICLE_BLOG_VIEW_SUCCESS,
      payload: {
        message: 'Data article view fetch success!',
        data: card,
      },
    });
  } catch (err) {
    dispatch({
      type: FETCH_ARTICLE_BLOG_VIEW_ERROR,
      error: err,
    });
  }
};
