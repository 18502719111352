export const offerViewQuery = `{
  sectionOrSubpages(where: {sectionName: "offerView"}) {
    sectionName
    dots
    sectionOrPageTitle {
      title
      arrowLength
    }
    cards {
      id
      cardInsideTitle
      cardParagraphs
      cardImage {
        url
      }
      cardTypeRight
      cardBck
      textItalic
      accordionBelow{
        id
        title
        description
      }
    }
  }
}`;
