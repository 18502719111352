import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardContent from 'components/atoms/CardContent';
import Quotation from 'components/molecules/Quotation';
import breakpoints from 'breakpoints';
import circles from 'assets/icons/circles.svg';
import { ReactSVG } from 'react-svg';

const StyledCardWrapper = styled.div`
  position: relative;
  margin-top: 10rem;
  z-index: 0;
  :first-child{
    margin-top: 0rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: 10rem;
  }
`;

const StyledBck = styled(ReactSVG)`
  position: absolute;
  top: ${({ $quotationAbove }) => ($quotationAbove ? '0' : '-3.5rem')}; 
  left: -32%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  >svg{
    width: 70%;
    color: ${({ theme }) => theme.color.secondaryLigt};
  }
`;

const CardNoHeading = ({
  cardInsideTitle, cardParagraphs, paragraphsIcons, cardImage, cardTypeRight, cardBck, quotationAbove, textItalic, link, id,
}) => (
  <StyledCardWrapper>
    {!cardBck
    && (
    <StyledBck
      src={circles}
      $quotationAbove={quotationAbove}
    />
    )}
    {quotationAbove
    && (
    <Quotation
      text={quotationAbove.text}
      img={quotationAbove.img}
      author={quotationAbove.author}
    />
    )}
    <CardContent
      id={id}
      cardInsideTitle={cardInsideTitle}
      cardParagraphs={cardParagraphs}
      paragraphsIcons={paragraphsIcons}
      link={link}
      cardImage={cardImage}
      cardTypeRight={cardTypeRight}
      cardBck={cardBck}
      textItalic={textItalic}
    />
  </StyledCardWrapper>
);

CardNoHeading.propTypes = {
  id: PropTypes.string.isRequired,
  cardInsideTitle: PropTypes.string.isRequired,
  paragraphsIcons: PropTypes.arrayOf(PropTypes.object),
  quotationAbove: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ),
  cardParagraphs: PropTypes.string.isRequired,
  cardImage: PropTypes.array,
  link: PropTypes.objectOf(PropTypes.string),
  textItalic: PropTypes.bool,
  cardTypeRight: PropTypes.bool,
  cardBck: PropTypes.bool,
};

CardNoHeading.defaultProps = {
  paragraphsIcons: [],
  quotationAbove: null,
  cardImage: null,
  textItalic: false,
  cardTypeRight: false,
  cardBck: false,
  link: null,
};

export default CardNoHeading;
