const breakpoints = {
  XS: '480px',
  S: '660px',
  M: '850px',
  L: '1250px',
  XL: '1600px',
  XXL: '2500px',
};

export default breakpoints;
