import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Wrapper from 'templates/Wrapper';
import breakpoints from 'breakpoints';
import { dotsBck } from 'assets/backgrounds/dotsBck';

const StyledSection = styled.section`
  padding: 4rem 0;
  background-color: ${({ bckcolor }) => bckcolor};
  background-image: ${({ bckimage = [], blend = [] }) => `linear-gradient(0deg, ${blend[0]}${blend[1]} 0%, ${blend[0]}${blend[1]} 100%), url(${bckimage[0]?.url})`};
  background-attachment: ${({ attachement }) => attachement};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ bckcolor, bckimage, dots }) => ((!bckcolor && !bckimage.length && dots) ? css`
    position: relative;
    z-index: -100;
    background-color: #ffffff;
    background-image: ${`linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%), ${dotsBck}`};
    background-size: 250%;
    background-position: right top;
    background-repeat: repeat-y;

    @media screen and (min-width: ${breakpoints.S}) {
      background-size: 180%;
    }

    @media screen and (min-width: ${breakpoints.M}) {
      background-image: ${`linear-gradient(90deg, rgba(255,255,255,1) 85%, rgba(255,255,255,0.3) 100%), ${dotsBck}`};
      background-size: 140%;
    }

    @media screen and (min-width: ${breakpoints.L}) {
      background-size: 110%;
    }

    @media screen and (min-width: ${breakpoints.XL}) {
      background-image: ${`linear-gradient(90deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0.3) 100%), ${dotsBck}`};
      background-size: 80%;
    }

  ` : null)}
`;

const Section = ({
  children, bckcolor, bckimage, className, blend, attachement = 'scroll', dots = false, component,
}) => (
  <StyledSection
    as={component}
    className={className}
    bckcolor={bckcolor}
    bckimage={bckimage}
    blend={blend}
    attachement={attachement}
    dots={dots}
  >
    <Wrapper>
      {children}
    </Wrapper>
  </StyledSection>
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bckcolor: PropTypes.string,
  bckimage: PropTypes.arrayOf(
    PropTypes.object,
  ),
  className: PropTypes.string,
  blend: PropTypes.arrayOf(
    PropTypes.string,
  ),
  attachement: PropTypes.string,
  dots: PropTypes.bool,
  component: PropTypes.string,
};

Section.defaultProps = {
  bckcolor: null,
  className: null,
  blend: [],
  bckimage: [],
  attachement: 'scroll',
  dots: false,
  component: 'section',
};

export default Section;
