import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Section from 'templates/Section';
import mainTheme from 'themes/mainTheme';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';
import logoSmall from 'assets/logos/logoSmall.svg';
import basket from 'assets/icons/basket.svg';
import { routes } from 'routes';

const StyledFooter = styled.footer`
  margin-top: auto;
`;

const StyledMainFooterSection = styled(Section)`
  padding: 2rem 0;
  
  @media screen and (min-width: ${breakpoints.S}){
    padding: 4rem 0;
  }
`;

const StyledBottomFooterSection = styled(Section)`
  padding: 1rem 0;
`;

const StyledCopyrightsBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  >span{
    position: relative;
    font-size: .8em;
    margin-top: .5rem;
    >a{
      text-decoration: none;
      color: ${({ theme }) => theme.color.secondary};
    }
  }

  @media screen and (min-width: ${breakpoints.S}){
    justify-content: center;
    flex-direction: row;
    >span{
      position: absolute;
      margin-top: 0;
      right: 0;
      top: 0;
      font-size: 1em;
    }
  }
`;

const StyledMainFooterContainer = styled.div`
`;

const StyledLogoContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoints.S}){
    justify-content: flex-start;
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDataBox = styled.div`
  padding: 3rem 0 0 0;

  @media screen and (min-width: ${breakpoints.XS}){
    padding: 1.5rem;
  }
`;

const StyledNavBox = styled.ul`
  display: none;
  position: relative;
  padding: 1rem;

  @media screen and (min-width: ${breakpoints.M}){
    display: block;
  }
`;

const StyledListItem = styled.li`
  list-style: none;
`;

const StyledLogo = styled(ReactSVG)`
  color: ${({ theme }) => theme.color.white};
    >svg{
      width: 170px;
      height: auto;
    }

  @media screen and (min-width: ${breakpoints.S}){
    >svg{
      width: auto;
      height: auto;
    }
  }
`;

const StyledBasketIcon = styled(ReactSVG)`
  color: ${({ theme }) => theme.color.white};
`;

const StyledIconLink = styled(Link)`
  position: absolute;
  top: -6.5rem;
  left: 0;
  :hover{
    ${StyledBasketIcon} {
    color: ${({ theme }) => theme.color.primaryLight};
    }
  }
`;

const StyledIcon = styled(ReactSVG)`
  position: absolute;
  top: 0;
  left: -55px;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  >svg{
    padding: 0.5rem;
    height: ${({ theme }) => theme.fontSize.l};
    width: 100%;
  }
`;

const StyledParagraphBox = styled.div`
  position: relative;
  margin-left: 45px;
  margin-top: ${({ marginTop }) => marginTop && '1.5rem'};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ small, theme }) => (small === true ? theme.fontSize.xxxs : theme.fontSize.xxs)};
  
  @media screen and (min-width: ${breakpoints.S}){
    font-size: ${({ small, theme }) => (small === true ? theme.fontSize.xxs : theme.fontSize.s)};
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 1rem;
  text-decoration: none;
  &::before {
    opacity: 0;
    content: '';
    position: absolute;
    top: calc(100%);
    left: 0;
    height: 1px;
    width: 80%;
    background-color: ${({ theme }) => theme.color.white};
    transform-origin: 50% 0%;
    transform: translate3d(0, 3px, 0);
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.8, 1);
    transition-delay: 0.1s;
}

:hover::before {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
}
`;

const Footer = ({ navigation = [], dataBoxLeft, dataBoxRight }) => (
  <StyledFooter>
    <StyledMainFooterSection
      component="div"
      bckcolor={mainTheme.color.primary}
    >
      <StyledMainFooterContainer>
        <StyledLogoContainer>
          <StyledLogo
            src={logoSmall}
          />
        </StyledLogoContainer>
        <StyledContentContainer>
          <StyledDataBox>
            {dataBoxLeft.map(({
              txt, icon, marginTop, small,
            }, index) => (
              <StyledParagraphBox
                key={txt + index}
                small={small}
                marginTop={marginTop}
              >
                <p>{txt}</p>
                {icon && <StyledIcon src={icon?.url} />}
              </StyledParagraphBox>
            ))}
          </StyledDataBox>
          <StyledNavBox>
            <StyledListItem>
              <StyledIconLink
                to={routes.shop}
              >
                <StyledBasketIcon
                  src={basket}
                />
              </StyledIconLink>
            </StyledListItem>
            {Object.entries(routes).map(([name, route]) => (
              <StyledListItem
                key={route}
              >
                <StyledLink
                  key={route}
                  to={route}
                >
                  {navigation[name]}
                </StyledLink>
              </StyledListItem>
            ))}
          </StyledNavBox>
          <StyledDataBox>
            {dataBoxRight.map(({
              txt, icon, marginTop, small,
            }, index) => (
              <StyledParagraphBox
                key={index}
                small={small}
                marginTop={marginTop}
              >
                <p>{txt}</p>
                {icon && <StyledIcon src={icon?.url} />}
              </StyledParagraphBox>
            ))}
          </StyledDataBox>
        </StyledContentContainer>
      </StyledMainFooterContainer>
    </StyledMainFooterSection>

    <StyledBottomFooterSection
      component="div"
      bckcolor={mainTheme.color.primaryLight}
    >
      <StyledCopyrightsBox>
        <p>
          &copy;
          {' '}
          {new Date().getFullYear()}
          {' '}
          Bluechip Sp. J.
        </p>
        <span>
          design & code:
          {' '}
          <a href="https://github.com/mapawel">mapawel</a>
        </span>
      </StyledCopyrightsBox>
    </StyledBottomFooterSection>
  </StyledFooter>
);

Footer.propTypes = {
  navigation: PropTypes.objectOf(PropTypes.string).isRequired,
  dataBoxLeft: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
  ).isRequired,
  dataBoxRight: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object,
      ]),
    ),
  ).isRequired,
};

const mapStateToProps = ({
  data: {
    navigation = {},
    footer: { dataBoxLeft = [], dataBoxRight = [] } = {},
  } = {},
}) => ({
  navigation,
  dataBoxLeft,
  dataBoxRight,
});

export default connect(mapStateToProps)(Footer);
