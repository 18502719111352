export const contactViewQuery = `{
  sectionOrSubpages(
    where: {sectionName: "contactView"}
  ) {
    sectionName
    dots
    sectionOrPageTitle {
      title
      arrowLength
    }
    cards {
      id
cardInsideTitle
      cardParagraphs
      paragraphsIcons {
        url
      }
      cardImage {
        url
      }
      cardTypeRight
      cardBck
      textItalic
    }
  }
}`;
