import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const StyledTitleContainer = styled.div`
  margin-top: 0;
  margin-bottom: 4rem;

  @media screen and (min-width: ${breakpoints.S}) {
    margin-top: 4rem;
    margin-bottom: 7rem;
  }
`;

const StyledTitleTxt = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme, color }) => (color || theme.color.secondary)};

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

const StyledArrow = styled.div`
  position: relative;
  display: block;
  width: ${({ arrowlength }) => `${arrowlength * 100 * 0.6}px`};
  height: 1px;
  margin: 1.5rem 0;
  background-color: ${({ theme, color }) => (color || theme.color.secondary)};
  ::before, ::after{
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    width: 9px;
    height: 1px;
    background-color: ${({ theme, color }) => (color || theme.color.secondary)};
    transform-origin: right;
    transform: translateY(calc(-100% + 1px)) rotate(40deg);
  }
  ::after{
    transform: translateY(calc(100% - 1px)) rotate(-40deg);
  }

  @media screen and (min-width: ${breakpoints.S}) {
    width: ${({ arrowlength }) => `${arrowlength * 100}px`};
  }
`;

const SectionTitle = ({
  arrowlength, children, color, component,
}) => {
  const titleRef = useRef(null);
  const arrowRef = useRef(null);

  useEffect(() => {
    gsap.from(titleRef.current, {
      duration: 0.6,
      y: '+=10',
      opacity: 0,
      scrollTrigger: {
        trigger: titleRef.current,
        start: 'top 70%',
      },
    });
    gsap.from(arrowRef.current, {
      duration: 0.6,
      x: '-=200',
      opacity: 0,
      scrollTrigger: {
        trigger: titleRef.current,
        start: 'top 70%',
      },
    });
  }, []);

  return (
    <StyledTitleContainer>
      <StyledTitleTxt
        as={component}
        ref={titleRef}
        color={color}
      >
        {children?.split('~').map((p, index) => (
          <React.Fragment
            key={p + index}
          >
            {p.toLowerCase() === 'bluechip' ? <strong>{p}</strong> : p}
            {' '}
            <br />
          </React.Fragment>
        ))}
      </StyledTitleTxt>
      <StyledArrow
        ref={arrowRef}
        arrowlength={arrowlength}
        color={color}
      />
    </StyledTitleContainer>
  );
};

SectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  arrowlength: PropTypes.number,
  color: PropTypes.string,
  component: PropTypes.string,
};

SectionTitle.defaultProps = {
  children: null,
  arrowlength: 0,
  component: null,
  color: null,
};

export default SectionTitle;
