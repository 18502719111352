import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import dataReducer from 'reducers/dataReducer';
import errorReducer from 'reducers/errorReducer';

const store = createStore(
  combineReducers({
    error: errorReducer,
    data: dataReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
