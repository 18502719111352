import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import breakpoints from 'breakpoints';

const StyledFirstLine = styled.span`
  display: block;
  margin-bottom: 3rem;
  font-family: 'Dancing Script', cursive;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.secondary};

  @media screen and (min-width: ${breakpoints.XS}) {
  font-size: ${({ theme }) => theme.fontSize.xl};
  }

  @media screen and (min-width: ${breakpoints.S}) {
  font-size: ${({ theme }) => theme.fontSize.xxl};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
  font-size: ${({ theme }) => theme.fontSize.xxxl};
  }
`;

const StyledSecondLine = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.color.primary};
  margin-left: 4rem;

  @media screen and (min-width: ${breakpoints.XS}) {
  font-size: ${({ theme }) => theme.fontSize.xl};
  }

  @media screen and (min-width: ${breakpoints.S}) {
  font-size: ${({ theme }) => theme.fontSize.xxl};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
  font-size: ${({ theme }) => theme.fontSize.xxxl};
  margin-left: 10rem;
  }
`;

const HeaderHeading = ({ headingText }) => (
  <>
    <StyledFirstLine>
      {headingText[0]}
    </StyledFirstLine>
    <StyledSecondLine>
      {headingText[1]}
    </StyledSecondLine>
  </>
);

HeaderHeading.propTypes = {
  headingText: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = ({ data: { header: { headingText = [] } = {} } }) => ({
  headingText,
});

export default connect(mapStateToProps)(HeaderHeading);
