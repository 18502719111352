const mainTheme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#4F8CBA',
    primaryLight: '#D7E5EF',
    secondary: '#5F5F5F',
    secondaryLigt: '#F1F1F1',
    cta: '#BA6361',
    lightGlass: '#4F8CBA1A',
    darkGlass: '#4F8CBAD4',
  },
  fontSize: {
    xxxs: '1rem',
    xxs: '1.4rem',
    xs: '1.6rem',
    s: '2.2rem',
    m: '2.4rem',
    l: '3.6rem',
    xl: '5.6rem',
    xxl: '6.4rem',
    xxxl: '8.4rem',
  },
  fontWeight: {
    light: '300',
    regular: '400',
    semibold: '600',
    bold: '700',
    black: '800',
  },
  fontFamily: {
    standard: '"Open Sans", sans-serif',
    serif: '"Source Serif Pro", serif',
  },
};

export default mainTheme;
