import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNavHeader, fetchFooter } from 'actions';

export const useFetchView = (fetchAction, fetchedPart, blogArticleId) => {
  const [fetchning, setFetching] = useState(false);
  const [isAllFetched, setAllFetched] = useState(false);

  const { dataFetched = {} } = useSelector(({ data: { dataFetched = {} } = {} }) => ({
    dataFetched,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    const articleFetched = dataFetched.article.find((article) => article === fetchedPart);

    const isMainViewFetched = (dataFetched[fetchedPart] || articleFetched);
    const isRootTemplateFetched = (dataFetched.navAndHeader && dataFetched.footer);
    if (isMainViewFetched && isRootTemplateFetched) setAllFetched(true);

    if (dataFetched[fetchedPart] || fetchning || articleFetched) return;
    dispatch(fetchAction(blogArticleId));
    if (!dataFetched.navAndHeader) dispatch(fetchNavHeader());
    if (!dataFetched.footer) dispatch(fetchFooter());
    setFetching(true);
  }, [fetchning, dataFetched, dispatch, fetchAction, fetchedPart, blogArticleId]);

  return isAllFetched;
};
