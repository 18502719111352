import React from 'react';
import { ReactSVG } from 'react-svg';
import styled, { keyframes } from 'styled-components';
import spinnerIcon from 'assets/icons/spinner.svg';

const spin = keyframes`
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  margin-top: -20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledSpinner = styled(ReactSVG)`
  width: 80px;
  color: ${({ theme }) => theme.color.secondary};
  >svg{
    animation: ${spin} 1s linear infinite;
  }
`;
const LoadingTxt = styled.p`
  margin-top: 5rem;
  font-size: ${({ theme }) => theme.fontSize.l};
  color: ${({ theme }) => theme.color.secondary};
`;

const Spinner = () => (
  <Backdrop>
    <Container>
      <StyledSpinner
        src={spinnerIcon}
      />
      <LoadingTxt>Loading ...</LoadingTxt>
    </Container>

  </Backdrop>
);

export default Spinner;
