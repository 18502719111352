export const footerQuery = `{
  footers {
    footerDataBoxesLeft {
      txt
      icon {
        url
      }
      marginTop
      small
    }
    footerDataBoxesRight{
      txt
      icon {
        url
      }
      marginTop
      small
    }
  }
}`;
