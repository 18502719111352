import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Helmet from 'templates/PageHelmet';
import Section from 'templates/Section';
import SectionTitle from 'components/atoms/SectionTitle';
import mainTheme from 'themes/mainTheme';
import CardNoHeading from 'components/molecules/CardNoHeading';
import { fetchContactView } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const StyledSection = styled(Section)`
  margin-top: 5rem;
`;

const Contact = ({
  title, cards, dots,
}) => {
  const isFetched = useFetchView(fetchContactView, 'contact');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="Skontaktuj się z nami | Bluechip - kontakt"
        description="Czekamy na Ciebie! Z przyjemnością przedstawimy kompleksową ofertę, dzięki której zaoszczędzisz czas i zyskasz przewagi w biznesie"
      />
      {isFetched ? (
        <StyledSection
          dots={dots}
        >
          <SectionTitle
            component="h1"
            bckcolor={mainTheme.color.white}
            arrowlength={title?.arrowLength}
          >
            {title?.title}
          </SectionTitle>
          {cards.map((card, index) => (
            <CardNoHeading
              id={card.id}
              key={index}
              cardInsideTitle={card.cardInsideTitle}
              cardParagraphs={card.cardParagraphs}
              cardImage={card.cardImage}
              cardTypeRight={card.cardTypeRight}
              cardBck={card.cardBck}
              quotationAbove={card.quotationAbove}
              paragraphsIcons={card.paragraphsIcons}
            />
          ))}
        </StyledSection>
      ) : (
        <Spinner />
      )}

    </>
  );
};

Contact.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]),
    ),
  ).isRequired,
  dots: PropTypes.bool,
};

Contact.defaultProps = {
  dots: false,
};

const mapStateToProps = ({ data: { contactView: { title = {}, cards = [], dots } = {}, dataFetched = {} } = {} }) => ({
  title,
  cards,
  dots,
  dataFetched,
});

export default connect(mapStateToProps)(Contact);
