import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const usePagination = () => {
  const { cards = [] } = useSelector(({ data: { blogView: { cards = [] } = {} } }) => ({
    cards,
  }));

  const elementsQtyOptions = [3, 8, 20];
  const [elementsQty, setElementsQty] = useState(elementsQtyOptions[0]);
  const [page, setPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [oneBackDisabled, setOneBackDisabled] = useState(false);
  const [oneFrwDisabled, setOneFrwDisabled] = useState(false);
  const [allBackDisabled, setAllBackDisabled] = useState(false);
  const [allFrwDisabled, setAllFrwDisabled] = useState(false);
  const [displaiedCard, setDisplaiedCard] = useState([]);

  useEffect(() => {
    if (!cards.length) return;
    const start = (+page * +elementsQty) - +elementsQty;
    const end = start + +elementsQty;
    const filteredCards = cards
      .sort((a, b) => (moment(b.creationDate) - moment(a.creationDate)))
      .slice(start, end);

    const elements = cards.length;
    const pages = Math.ceil(elements / elementsQty) || 1;
    setAllPages(pages);

    setDisplaiedCard(filteredCards);
  }, [cards, page, elementsQty]);

  useEffect(() => {
    if (page === 0) setPage(1);
    if (page > allPages) setPage(allPages);
    if (page === 1) {
      setOneBackDisabled(true);
      setAllBackDisabled(true);
    } else {
      setOneBackDisabled(false);
      setAllBackDisabled(false);
    }
    if (page >= allPages) {
      setOneFrwDisabled(true);
      setAllFrwDisabled(true);
    } else {
      setOneFrwDisabled(false);
      setAllFrwDisabled(false);
    }
  }, [page, allPages]);

  const changePage = (change) => {
    if (change === 'first') {
      return setPage(1);
    }
    if (change === 'last') {
      return setPage(allPages);
    }
    setPage(page + change);
  };

  const changeElementsQty = (e) => {
    const { value } = e.target;
    setElementsQty(value);
  };

  return {
    displaiedCard,
    allBackDisabled,
    allFrwDisabled,
    oneBackDisabled,
    oneFrwDisabled,
    elementsQtyOptions,
    page,
    allPages,
    changePage,
    changeElementsQty,
  };
};

export default usePagination;
