import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import Button from 'components/atoms/Button';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const StyledContainer = styled.div`
  flex-direction: column;
  max-width: 600px;
  margin-bottom: 10rem;

  @media screen and (min-width: ${breakpoints.XL}) {
    max-width: 900px;
  }
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-top: 3rem;
`;

const StyledSubheadingTxt = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.light};

  @media screen and (min-height: 880px) {
    font-size: 3rem;
    margin-bottom: 3.5rem;
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const HeaderSubHeading = ({ className, subheadingTxt = ''}) => {
  const subheadingRef = useRef(null);

  const scroll = () => {
    gsap.to(window, { duration: 0.3, scrollTo: { y: '#main', offsetY: 74 } });
  };

  useEffect(() => {
    gsap.from(subheadingRef.current, {
      delay: 1,
      duration: 0.5,
      opacity: 0,
      y: '+=10',
    });
  }, []);

  return (
    <StyledContainer
      ref={subheadingRef}
      className={className}
    >
      <StyledSubheadingTxt>
        {subheadingTxt}
      </StyledSubheadingTxt>
      <StyledButton
        onClick={scroll}
      >
        sprawdź
      </StyledButton>
    </StyledContainer>
  );
};

HeaderSubHeading.propTypes = {
  className: PropTypes.string.isRequired,
  subheadingTxt: PropTypes.string,
};

HeaderSubHeading.defaultProps = {
  subheadingTxt: '',
};

const mapStateToProps = ({ data: { header: { subheadingTxt } = {} } }) => ({
  subheadingTxt,
});

export default connect(mapStateToProps)(HeaderSubHeading);
