import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Linkto from 'components/atoms/Linkto';
import breakpoints from 'breakpoints';
import { ReactSVG } from 'react-svg';
import { routes } from 'routes';

const StyledContentWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: stretch;

  @media screen and (min-width: ${breakpoints.L}) {
    flex-direction: row;
    flex-direction: ${({ $cardTypeRight }) => ($cardTypeRight ? 'row-reverse' : 'row')};
  }
`;

const StyledTxtBox = styled.div`
  position: relative;
  z-index: 0;
  display: block;
  width: 100%;
  padding: 0 1rem;
  margin-top: 4rem;

  @media screen and (min-width: ${breakpoints.L}) {
    width: 65%;
    margin-top: 0;
    padding: 0 5rem;
    padding-right: 5rem;
  }

  ${({ $cardInsideTitle, $cardBck }) => $cardInsideTitle && css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    padding: 4rem 3rem;

    
    @media screen and (min-width: ${breakpoints.L}) {
      padding: 15rem 5rem;
    }
  `}

  ${({ $cardBck, $cardTypeRight }) => $cardBck && css`
    ::before{
      position: absolute;
      content: '';
      top: 0;
      left: ${($cardTypeRight ? '0' : 'auto')};
      right: ${($cardTypeRight ? 'auto' : '0')};
      width: 100vw;
      height: 100%;
      background-color: ${({ theme }) => theme.color.primary};
    }
    @media screen and (min-width: ${breakpoints.L}) {
    ::before{
      left: ${($cardTypeRight ? '-10rem' : 'auto')};
      right: ${($cardTypeRight ? 'auto' : '-10rem')};
    }
  }
  `}
`;

const StyledTxt = styled.p`
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
  color: ${({ theme, $cardBck }) => ($cardBck ? theme.color.white : theme.color.black)};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  line-height: 1.6;
  font-style: ${({ $textItalic }) => $textItalic && 'italic'};
  :last-of-type{
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    line-height: 1.5;
    font-size: ${({ theme }) => theme.fontSize.s};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    line-height: 1.6;
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

const StyledImgBox = styled.div`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 350px;
  align-self: flex-end;
  background-image: ${({ cardImage }) => cardImage && `url(${cardImage?.[0].url})`};
  background-repeat: no-repeat;
  background-position: center;

  
  @media screen and (min-width: ${breakpoints.S}) {
    height: 400px;
  }
  
  @media screen and (min-width: ${breakpoints.M}) {
    height: 500px;
    margin-bottom: 7rem;
    background-size: contain;
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    z-index: 0;
    width: 35%;
    height: 400px;
    margin-bottom: 1rem;
    background-size: auto;
    /* background-position: bottom; */
  }
  
  @media screen and (min-width: ${breakpoints.XL}) {
    height: 480px;
  }

  ${({ $cardInsideTitle }) => $cardInsideTitle && css`
    align-self: stretch;

    @media screen and (min-width: ${breakpoints.M}) {
      margin-bottom: 0;
    }
    @media screen and (min-width: ${breakpoints.L}) {
      height: auto;
      width: 32%;
      margin-bottom: 0;
    }
  `}

`;

const StyledLinkto = styled(Linkto)`
  margin-top: 3rem;
`;

const StyledInsideTitle = styled.h2`
  position: relative;
  margin-left: -1rem;
  margin-bottom: 1.5rem;
  color: ${({ theme, $cardBck }) => ($cardBck ? theme.color.white : theme.color.secondary)};
  font-size: ${({ theme }) => theme.fontSize.m};
  
  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    margin-left: -4rem;
    margin-bottom: 3rem;
  }
`;

const StyledParagraphBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  :last-of-type{
    margin-bottom: 0;
  }
`;

const StyledIcon = styled(ReactSVG)`
  margin-right: 2rem;
  color: ${({ theme }) => theme.color.white};
  >svg{
    height: 30px;
    width: 30px;
    }

    @media screen and (min-width: ${breakpoints.M}) {
      margin-right: 4rem;
      >svg{
        height: 50px;
        width: 50px;
        }
    }

    @media screen and (min-width: ${breakpoints.L}) {
      >svg{
        height: 60px;
        width: 60px;
        }
    }
`;

const CardContent = ({
  children, cardParagraphs = '', paragraphsIcons = [], cardImage, cardTypeRight, link, cardInsideTitle, cardBck, textItalic, path, cardTitle, id,
}) => {
  let finalLink;
  const regPattern = /\W/g;
  if (link) {
    if (link.to === 'article') {
      finalLink = `${path}/${
        cardTitle
          .toLowerCase()
          .replace(/\u0142/g, 'l')
          .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
          .replaceAll(' ', '_')
          .replaceAll(regPattern, '')
      }/${id}`;
    } else {
      finalLink = routes[link.to];
    }
  }

  return (
    <StyledContentWrapper
      $cardTypeRight={cardTypeRight}
    >
      <StyledTxtBox
        $cardInsideTitle={cardInsideTitle}
        $cardBck={cardBck}
        $cardTypeRight={cardTypeRight}
      >
        {children}
        {cardInsideTitle && (
        <StyledInsideTitle
          $cardBck={cardBck}
        >
          {cardInsideTitle}
        </StyledInsideTitle>
        )}
        {cardParagraphs?.split('|').map((par, index) => (
          <StyledParagraphBox
            key={par + index}
          >
            {paragraphsIcons?.length !== 0 && (
              <StyledIcon
                src={paragraphsIcons?.[index].url}
              />
            )}
            <StyledTxt
              key={par}
              $cardBck={cardBck}
              $textItalic={textItalic}
            >
              {par?.split('~').map((p, index) => (
                <React.Fragment
                  key={p + index}
                >
                  {p}
                  {' '}
                  <br />
                </React.Fragment>
              ))}
            </StyledTxt>
          </StyledParagraphBox>
        ))}
        {link && (
        <StyledLinkto
          to={finalLink}
        >
          {link.name}
        </StyledLinkto>
        )}
      </StyledTxtBox>
      <StyledImgBox
        cardImage={cardImage}
        $cardInsideTitle={cardInsideTitle}
      />
    </StyledContentWrapper>
  );
};

CardContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cardParagraphs: PropTypes.string,
  paragraphsIcons: PropTypes.arrayOf(PropTypes.object),
  cardImage: PropTypes.arrayOf(PropTypes.object),
  cardTypeRight: PropTypes.bool,
  cardBck: PropTypes.bool,
  link: PropTypes.objectOf(PropTypes.string),
  cardInsideTitle: PropTypes.string,
  textItalic: PropTypes.bool,
  path: PropTypes.string,
  cardTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
};

CardContent.defaultProps = {
  children: null,
  cardParagraphs: null,
  paragraphsIcons: [],
  cardImage: null,
  cardTypeRight: false,
  cardBck: false,
  cardTitle: null,
  textItalic: false,
  link: null,
  cardInsideTitle: null,
  path: null,
};

export default CardContent;
