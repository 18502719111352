import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';

const StyledGlassBox = styled.div`
  padding: ${({ size }) => `${size * 0.4}rem ${size * 0.6}rem`};
  border-radius: ${({ round }) => (round ? '3px' : '0')};
  background-color: ${({ theme }) => `${theme.color.primary}2A`};
  box-shadow: ${({ theme }) => `0 8px 42px -9px ${theme.color.primary}4A`};
  border: 1px solid #FFFFFF33;
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );

  @media screen and (min-width: ${breakpoints.L}) {
    padding: ${({ size }) => `${size * 0.6}rem ${size * 1}rem`};
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    padding: ${({ size }) => `${size}rem ${size * 1.3}rem`};
  }
`;

const Glass = React.forwardRef(({
  className, size, round, children, component,
}, ref) => (
  <StyledGlassBox
    ref={ref}
    as={component}
    className={className}
    size={size}
    round={round}
  >
    {children}
  </StyledGlassBox>
));

Glass.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Glass;
