import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import mainTheme from 'themes/mainTheme';
import breakpoints from 'breakpoints';

const StyledSlide = styled.div`
  background-image: ${({ bck }) => `url(${bck})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 350px;

  @media screen and (min-width: ${breakpoints.S}) {
    height: 480px;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    height: 600px;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    height: 800px;
  }
`;

const StyledInnerSlide = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
`;

const StyledInnerSlideBar = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 40%;
  bottom: 0;
  background-image: ${({ blend }) => `linear-gradient(0deg, ${blend[0]}${blend[1]} 0%, ${blend[0]}${blend[1]} 100%)`};
  box-shadow: ${({ theme }) => `0 8px 42px -9px ${theme.color.primary}4A`};
  border: 1px solid #FFFFFF33;
  padding: 1rem 2rem;

  @media screen and (min-width: ${breakpoints.S}) {
    height: 35%;
    padding: 2rem 3rem;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    padding: 2rem 8rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    padding: 2rem 10rem;
    transition: transform .25s;
    transform: scale(.65);
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    height: 30%;
    padding: 2rem 12rem;
  }
`;

const StyledBarHeader = styled.h4`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.s};
  margin-bottom: 1rem;

  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.m};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.xxl};
    margin-bottom: 2rem;
    transition: transform .25s;
    transform: translateY(0.5rem);
  }
`;

const StyledBarDescription = styled.p`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }

  @media screen and (min-width: ${breakpoints.L}) {
    font-size: ${({ theme }) => theme.fontSize.m};
    transition: transform .25s;
    transform: translateY(0.5rem);
  }
`;

const NewsInnerSlide = ({ title, description, image }) => (
  <StyledSlide
    key={title + description}
    bck={image?.[0].url}
  >
    <StyledInnerSlide>
      <StyledInnerSlideBar
        className="slider-bar"
        blend={[mainTheme.color.primaryLight, 'AA']}
      >
        <StyledBarHeader
          className="slider-title"
        >
          {title}
        </StyledBarHeader>
        <StyledBarDescription
          className="slider-description"
        >
          {description}
        </StyledBarDescription>
      </StyledInnerSlideBar>
    </StyledInnerSlide>
  </StyledSlide>

);

NewsInnerSlide.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
};

export default NewsInnerSlide;
