import React, { useEffect } from 'react';
import Helmet from 'templates/PageHelmet';
import FullWidthParagraph from 'components/organisms/FullWidthParagraph';
import FullHeightContainer from 'templates/FullHeightContainer';
import StyledMain from 'templates/StyledMainWrapper';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import Header from 'components/organisms/Header';
import News from 'components/organisms/News';
import Figures from 'components/organisms/Figures';
import Advantages from 'components/organisms/Advantages';
import Brands from 'components/organisms/Brands';
import Offer from 'components/organisms/Offer';
import { fetchSections } from 'actions';
import { useFetchView } from 'hooks/useFetchView';
import Spinner from 'components/organisms/Spinner';

const MobileFullWidthParagraph = styled(FullWidthParagraph).attrs(() => ({ component: 'div' }))`
  display: block;

  @media screen and (min-width: ${breakpoints.S}) {
    display: none;
  }
`;

const DesktopFullWidthParagraph = styled(FullWidthParagraph)`
display: none;

  @media screen and (min-width: ${breakpoints.S}) {
    display: block;
  }
`;

const Home = () => {
  const isFetched = useFetchView(fetchSections, 'home');
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Helmet
        title="Tonery, tusze do drukarek i sprzęt IT dla biura i domu | Bluechip - home"
        description="Bluechip dostarcza tonery i tusze do drukarek oraz sprzęt komputerowy. Pomagamy sprzętowo biznesom, aby te mogły skupić się na swojej działalności."
      />
      {isFetched ? (
        <>
          <FullHeightContainer>
            <Header />
            <MobileFullWidthParagraph />
          </FullHeightContainer>
          <StyledMain>
            <DesktopFullWidthParagraph />
            <News />
            <Figures />
            <Advantages />
            <Brands />
            <Offer />
          </StyledMain>
        </>
      ) : (
        <Spinner />
      )}

    </>
  );
};

export default Home;
