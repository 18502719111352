import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import Linkto from 'components/atoms/Linkto';
import breakpoints from 'breakpoints';
import circles from 'assets/icons/circles.svg';
import { routes } from 'routes';

const StyledMainOfferWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 12rem;
`;

const StyledOfferWrapper = styled.li`
  position: relative;
  display: grid;
  grid-template-columns: 35% 65%;
  grid-template-rows: auto auto;
`;

const StyledOfferImgBox = styled.div`
  width: 100%;
  height: 0;
  padding: 50% 0;
  background-image: ${({ img }) => img && `url(${img?.url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const StyledHeadingBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 3rem 1rem;
  
  @media screen and (min-width: ${breakpoints.XS}) {
    padding: 3rem 1.5rem;
  }
  
  @media screen and (min-width: ${breakpoints.S}) {
    padding: 3rem 2rem;
  }
`;

const StyledOfferTxtHeading = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.color.secondary};
  
  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.l};
  }
`;

const StyledOfferTxt = styled.div`
  padding: 1rem;
  
  @media screen and (min-width: ${breakpoints.XS}) {
    padding: 1.5rem;
  }
  
  @media screen and (min-width: ${breakpoints.S}) {
    padding: 2rem;
  }
`;

const StyledOfferParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  margin-bottom: 2rem;
  :last-child{
    margin-bottom: 0;
  }
    
  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  
  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const StyledLink = styled(Linkto)`
  margin: 2rem 0;
`;

const StyledOfferBck = styled(ReactSVG)`
    position: absolute;
    z-index: -1;
    bottom: -10rem;
    left: -18rem;
    color: ${({ theme }) => theme.color.primaryLight};;
    >svg{
      width: 300px;
    }
    
`;

const MobileOfferBoxes = ({ className, offers }) => (
  <StyledMainOfferWrapper
    className={className}
  >
    {offers.map(({
      title, description, mainImg, secondImg, link,
    }, index) => (
      <StyledOfferWrapper
        key={index}
      >
        <StyledHeadingBox>
          <StyledOfferTxtHeading>
            {title}
          </StyledOfferTxtHeading>
          <StyledLink
            to={routes[link.to]}
          >
            {link.name}
          </StyledLink>
        </StyledHeadingBox>
        <StyledOfferImgBox
          img={mainImg}
        />
        <StyledOfferImgBox
          img={secondImg}
        />
        <StyledOfferTxt>
          {description.split('|').map((par, index) => (
            <StyledOfferParagraph
              key={index}
            >
              {par}
            </StyledOfferParagraph>
          ))}
        </StyledOfferTxt>
        <StyledOfferBck
          src={circles}
        />
      </StyledOfferWrapper>
    ))}
  </StyledMainOfferWrapper>
);

MobileOfferBoxes.propTypes = {
  className: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    ),
  ).isRequired,
};

const mapStateToProps = ({
  data: {
    offerSection: {
      offers = [],
    } = {},
  },
}) => ({
  offers,
});

export default connect(mapStateToProps)(MobileOfferBoxes);
