import styled from 'styled-components';
import breakpoints from 'breakpoints';

const Button = styled.button`
  position: relative;
  z-index: 0;
  padding-left: 3rem;
  padding-right: 3rem;
  height: 50px;
  min-width: 100px;
  border: none;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.cta};
  color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  line-height: 0.1;
  overflow: hidden;
  cursor: pointer;
  transition: transform .2s .1s;

  ::before{
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -10px;
    left: 10%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.primary};
    transform: translate(-50%, 0);
    transition: transform .4s;
  }
  
  :hover{
    transform: scale(1.02);
  }

  :hover::before{
    transform: translate(-50%, -50%) scale(40);
  }

  @media screen and (min-width: ${breakpoints.L}) {
    padding-left: 4rem;
    padding-right: 4rem;
    height: 60px;
    min-width: 150px;
    border-radius: 30px;
    font-size: ${({ theme }) => theme.fontSize.m};
  }
`;

export default Button;
