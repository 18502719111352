const initialState = {
  error: null,
  userErrorInfo: null,
};

const errorReducer = (state = initialState, action) => {
  const { error } = action;
  if (error) {
    console.error('MAIN ERROR App Handler: ', error.response?.data?.errors); // <<---------TO REMOVE
    return {
      ...state,
      error: [...(state.error ? (state.error) : []), ...error.response?.data?.errors],
      userErrorInfo: error.message,
    };
  }
  if (action.type === 'RESET_ERROR') {
    return {
      ...state,
      userErrorInfo: null,
    };
  }

  return state;
};

export default errorReducer;
