/* eslint-disable react/no-children-prop */
import React from 'react';
import RootTemplate from 'templates/RootTemplate';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from 'components/organisms/Navbar';
import Home from 'views/Home';
import About from 'views/About';
import Contact from 'views/Contact';
import OfferPage from 'views/OfferPage';
import Blog from 'views/Blog';
import Shop from 'views/Shop';
import BlogArticle from 'views/BlogArticle';
import { routes } from 'routes';
import ErrorModal from 'components/organisms/ErrorModal.js';

const Root = () => {
  const { userErrorInfo } = useSelector(({ error: { userErrorInfo = {} } = {} }) => ({
    userErrorInfo,
  }));

  return (
    <BrowserRouter>
      <RootTemplate>
        <Navbar />
        <Switch>
          <Route
            exact
            path={routes.home}
          >
            <Home />
          </Route>
          <Route
            path={routes.about}
          >
            <About />
          </Route>
          <Route
            path="/offer"
          >
            <OfferPage />
          </Route>
          <Route
            exact
            path={routes.blog}
            children={({ match }) => (
              <Blog match={match} />
            )}
          />
          <Route
            exact
            path={`${routes.blog}/:title`}
          >
            <Redirect to={routes.blog} />
          </Route>

          <Route
            path={`${routes.blog}/:title/:id`}
            children={({ match }) => (
              <BlogArticle match={match} />
            )}
          />
          <Route
            exact
            path={routes.contact}
          >
            <Contact />
          </Route>
          <Route
            exact
            path={routes.shop}
          >
            <Shop />
          </Route>

          <Route
            path="*"
          >
            <Redirect to={routes.home} />
          </Route>
        </Switch>
        {userErrorInfo && <ErrorModal>{userErrorInfo}</ErrorModal>}
      </RootTemplate>
    </BrowserRouter>
  );
};

export default Root;
