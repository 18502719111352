import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import breakpoints from 'breakpoints';
import mainTheme from 'themes/mainTheme';

const StyledBarBox = styled.div`
  padding: ${({ size }) => `${size * 0.4}rem ${size * 0.6}rem`};
  border-radius: ${({ round }) => (round ? '3px' : '0')};
  background-color: ${({ theme }) => theme.color.primaryLight};
  box-shadow: ${({ theme }) => `0 8px 42px -9px ${theme.color.primary}4A`};
  border: 1px solid #FFFFFF33;
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );

  @media screen and (min-width: ${breakpoints.L}) {
    padding: ${({ size }) => `${size * 0.4}rem ${size * 1.5}rem`};
  }
`;

const StyledAccordionTitle = styled.h3`
  margin-right: 1rem;
  font-size: ${({ theme }) => theme.fontSize.xs};
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary};

  ${({ $active }) => $active && css`
    color: ${({ theme }) => theme.color.white};
  `}

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
`;

const StyledCross = styled.button`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: none;
  border: ${({ theme, $active }) => ($active ? `2px solid ${theme.color.white}` : `2px solid ${theme.color.secondary}`)};
  flex-shrink: 0;
  cursor: pointer;
  transition: transform .3s;
    ::after, ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 70%;
      height: 2px;
      background-color: ${({ theme, $active }) => ($active ? theme.color.white : theme.color.secondary)};
      transition: transform .15s;
    }
    ::before {
      transform: translate(-50%, 0);
    }
    ::after {
      transform: translate(-50%, 0) rotate(90deg);
    }
    
  ${({ $active }) => $active && css`
  ::before {
      transform: translate(-50%, 0) rotate(45deg);
    }
    ::after {
      transform: translate(-50%, 0) rotate(135deg);
    }
  `}

  @media screen and (min-width: ${breakpoints.S}) {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    width: 46px;
    height: 46px;
  }
`;

const Bar = ({
  className, size, round, onClick, active, title,
}) => {
  const barRef = useRef(null);

  useEffect(() => {
    const tween = gsap.to(barRef.current,
      {
        backgroundColor: active ? mainTheme.color.primary : mainTheme.color.primaryLight,
        duration: 0.1,
        paused: true,
      });
    tween.play();
  }, [active]);

  return (
    <>
      <StyledBarBox
        ref={barRef}
        className={className}
        size={size}
        round={round}
        onClick={onClick}
      >
        <StyledAccordionTitle
          $active={active}
        >
          {title}
        </StyledAccordionTitle>
        <StyledCross
          $active={active}
        />
      </StyledBarBox>
    </>
  );
};

Bar.propTypes = {
  className: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  round: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

Bar.defaultProps = {
  round: false,
};

export default Bar;
