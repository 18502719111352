export const blogViewQuery = `{
  sectionOrSubpages(where: {sectionName: "blogView"}) {
    sectionName
    dots
    sectionOrPageTitle {
      title
      arrowLength
    }
    cards {
      id
      creationDate
      cardTitle
      cardSubtitle
      titleSmall
      cardParagraphs
      cardImage {
        url
      }
      link{
        name
        to
      }
      cardTypeRight
      cardBck
      textItalic
    }
  }
}`;
