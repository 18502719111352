import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 96%;
  max-width: 1600px;
  margin: 0 auto;
`;

export default Wrapper;
