import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import breakpoints from 'breakpoints';
import Bar from 'components/atoms/Bar';
import AccordionDescription from 'components/atoms/AccordionDescription';

const StyledAccordionContainer = styled.ul`
  margin-top: 3rem;
  margin-bottom: 6rem;
  list-style: none;
  
  @media screen and (min-width: ${breakpoints.S}) {
    margin-bottom: 18rem;
  }
  
  @media screen and (min-width: ${breakpoints.L}) {
    margin-top: 6rem;
    margin-bottom: 25rem;
  }
`;

const StyledAccordionElement = styled.li`
  padding: .5rem 1rem;
`;

const StyledBar = styled(Bar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: transform .1s;

  ${({ active }) => !active && css`
    :hover{
      transform: translateX(-1rem);
    }
  `}
`;

const Accordion = ({ data }) => {
  const [activeBar, setActiveBar] = useState(null);

  const toggleActive = (id) => {
    if (id === activeBar) setActiveBar(null);
    else setActiveBar(id);
  };

  return (
    <>
      <StyledAccordionContainer>
        {data.map(({ id, title, description }) => (
          <StyledAccordionElement
            key={id}
          >
            <StyledBar
              size={3}
              onClick={() => toggleActive(id)}
              active={id === activeBar}
              title={title}
            />
            <AccordionDescription
              id={id}
              active={id === activeBar}
              description={description}
            />
          </StyledAccordionElement>
        ))}
      </StyledAccordionContainer>
    </>
  );
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string),
  ).isRequired,
};

export default Accordion;
