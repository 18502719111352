import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import Glass from 'components/atoms/Glass';
import HeaderHeading from 'components/atoms/HeaderHeading';
import HeaderSubHeading from 'components/molecules/HeaderSubHeading';
import circlearrow from 'assets/icons/circlearrow.svg';
import { ReactSVG } from 'react-svg';
import gsap from 'gsap/gsap-core';

const StyledHeaderContainer = styled.div`
  position: relative;
  margin-top: auto;
  height: 100%;
  display: flex;

  @media screen and (min-height: 820px) and (min-width: ${breakpoints.S}) {
    height: calc(100vh - 76px);
  }
`;

const StyledHeaderImage = styled.div`
  background-image:  ${({ image }) => image && `url(${image[2]?.url})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 15%;
  margin-top: auto;
  height: 100%;
  width: 100%;
  flex-shrink: 0;

  @media screen and (min-width: ${breakpoints.S}) {
    background-image: ${({ image }) => image && `url(${image[1]?.url})`};
  }

  @media screen and (min-width: ${breakpoints.M}) {
    width: 55%;
  }

  @media screen and (min-width: ${breakpoints.XL}) {
    background-image: ${({ image }) => image && `url(${image[0]?.url})`};
  }
`;

const StyledGlass = styled(Glass)`
  position: absolute;
  width: 90%;
  max-width: 500px;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: ${breakpoints.M}) {
    position: absolute;
    width: auto;
    max-width: 100%;
    top: 5%;
    bottom: auto;
    left: 45%;
    transform: translateX(0);
  }
`;

const StyledHeaderSubHeading = styled(HeaderSubHeading)`
  display: none;

  @media screen and (min-width: ${breakpoints.L}) and (min-height: 700px) {
    align-self: flex-end;
    margin-left: 4rem;
    margin-right: 4rem;
    display: flex;
  }
`;

const StyledArrowIcon = styled(ReactSVG)`
  display: none;

  @media screen and (min-width: ${breakpoints.L}) {
    display: block;
    position: absolute;
    left: 55%;
    bottom: 0;
    margin-left: 4rem;
    margin-bottom: 2rem;
  }
`;

const Header = ({ images = [], imagesAlt = '' }) => {
  const glassRef = useRef(null);
  const arrowRef = useRef(null);

  useEffect(() => {
    gsap.from(glassRef.current, {
      delay: 0.3,
      duration: 1,
      scale: 0.85,
    });
    gsap.from(arrowRef.current, {
      delay: 2.5,
      duration: 1,
      opacity: 0,
    });
  }, []);

  return (
    <StyledHeaderContainer>
      <StyledHeaderImage
        image={images}
        alt={imagesAlt}
      />
      <StyledHeaderSubHeading />
      <StyledGlass
        ref={glassRef}
        size={6}
        round="true"
      >
        <HeaderHeading />
      </StyledGlass>
      <div
        ref={arrowRef}
      >
        <StyledArrowIcon
          src={circlearrow}
          alt="strzałka w dół"
        />
      </div>
    </StyledHeaderContainer>
  );
};

Header.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  imagesAlt: PropTypes.string,
};

Header.defaultProps = {
  images: [],
  imagesAlt: '',
};

const mapStateToProps = ({ data: { header: { images, imagesAlt } = {} } }) => ({
  images,
  imagesAlt,
});

export default connect(mapStateToProps)(Header);
