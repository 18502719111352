export const navHeaderQuery = `{
  navLinksDescriptions{
    home
    about
    offer
    contact
    blog
  }
  headers {
    images {
      url
    }
    imagesAlt
    headingText
    subheadingTxt
    headerButton
  }
  fullWidthParagraphs {
    text
    bckImages{
      url
    }
    bckBlendHex
    bckAttachement
  }
}`;
