import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Section from 'templates/Section';
import mainTheme from 'themes/mainTheme';
import SectionTitle from 'components/atoms/SectionTitle';
import NewsSwiper from 'components/molecules/Swipers/NewsSwiper';

const News = ({ title, slides }) => (
  <Section
    bckcolor={mainTheme.color.white}
  >
    <SectionTitle
      arrowlength={title?.arrowLength}
    >
      {title?.title}
    </SectionTitle>
    <NewsSwiper
      slides={slides}
    />
  </Section>
);

News.propTypes = {
  title: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  slides: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ])
    ),
  ).isRequired,
};

const mapStateToProps = ({ data: { newsSection: { title = {}, slides = [] } = {} } }) => ({
  title,
  slides,
});

export default connect(mapStateToProps)(News);
