import {
  FETCH_SECTIONS_REQUESTED, FETCH_SECTIONS_SUCCESS,
  FETCH_NAV_HEADER_REQUESTED, FETCH_NAV_HEADER_SUCCESS,
  FETCH_FOOTER_REQUESTED, FETCH_FOOTER_SUCCESS,
  FETCH_ABOUT_VIEW_REQUESTED, FETCH_ABOUT_VIEW_SUCCESS,
  FETCH_CONTACT_VIEW_REQUESTED, FETCH_CONTACT_VIEW_SUCCESS,
  FETCH_SHOP_VIEW_REQUESTED, FETCH_SHOP_VIEW_SUCCESS,
  FETCH_OFFER_VIEW_REQUESTED, FETCH_OFFER_VIEW_SUCCESS,
  FETCH_BLOG_VIEW_REQUESTED, FETCH_BLOG_VIEW_SUCCESS,
  FETCH_ARTICLE_BLOG_VIEW_REQUESTED, FETCH_ARTICLE_BLOG_VIEW_SUCCESS,
} from 'actions';

const initialState = {
  dataFetched: {
    navAndHeader: false,
    footer: false,
    home: false,
    about: false,
    contact: false,
    shop: false,
    offer: false,
    blog: false,
    article: [],
  },
};
let sections;
let sectionsToState;
let dataBoxLeft;
let dataBoxRight;

const dataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_NAV_HEADER_REQUESTED:
      return state;
    case FETCH_NAV_HEADER_SUCCESS:
      return {
        ...state,
        navigation: payload.data.navLinksDescriptions[0],
        header: payload.data.headers[0],
        fullWidthParagraph: payload.data.fullWidthParagraphs[0],
        dataFetched: {
          ...state.dataFetched,
          navAndHeader: true,
        },
      };

    case FETCH_SECTIONS_REQUESTED:
      return state;
    case FETCH_SECTIONS_SUCCESS:
      sections = payload.data.map((section) => (
        {
          [section.sectionName]: {
            title: section.sectionOrPageTitle,
            slides: section.rotatorSlides,
            figures: section.figures,
            bckImages: section.bckImages,
            bckBlendHex: section.bckBlendHex,
            cards: section.cards,
            dots: section.dots,
            logos: section.logos,
            bckAttachement: section.bckAttachement,
            offers: section.offersForOfferSections,
          },
        }
      ));

      sectionsToState = Object.assign({}, ...sections);
      return {
        ...state,
        ...sectionsToState,
        dataFetched: {
          ...state.dataFetched,
          home: true,
        },
      };

    case FETCH_FOOTER_REQUESTED:
      return state;
    case FETCH_FOOTER_SUCCESS:
      dataBoxLeft = payload.data.dataBoxLeft;
      dataBoxRight = payload.data.dataBoxRight;
      return {
        ...state,
        footer: {
          dataBoxLeft,
          dataBoxRight,
        },
        dataFetched: {
          ...state.dataFetched,
          footer: true,
        },
      };

    case FETCH_ABOUT_VIEW_REQUESTED:
      return state;
    case FETCH_ABOUT_VIEW_SUCCESS:
      return {
        ...state,
        ...payload.data,
        dataFetched: {
          ...state.dataFetched,
          about: true,
        },
      };

    case FETCH_CONTACT_VIEW_REQUESTED:
      return state;
    case FETCH_CONTACT_VIEW_SUCCESS:
      return {
        ...state,
        ...payload.data,
        dataFetched: {
          ...state.dataFetched,
          contact: true,
        },
      };

    case FETCH_SHOP_VIEW_REQUESTED:
      return state;
    case FETCH_SHOP_VIEW_SUCCESS:
      return {
        ...state,
        ...payload.data,
        dataFetched: {
          ...state.dataFetched,
          shop: true,
        },
      };

    case FETCH_OFFER_VIEW_REQUESTED:
      return state;
    case FETCH_OFFER_VIEW_SUCCESS:
      return {
        ...state,
        ...payload.data,
        dataFetched: {
          ...state.dataFetched,
          offer: true,
        },
      };

    case FETCH_BLOG_VIEW_REQUESTED:
      return state;
    case FETCH_BLOG_VIEW_SUCCESS:
      return {
        ...state,
        blogView: {
          ...payload.data.blogView,
          cards: state.blogView?.cards ? ([
            ...payload.data.blogView.cards.map((card) => ({ ...card, ...state.blogView.cards.find((el) => el.id === card.id) })),
          ]) : (
            [
              ...payload.data.blogView.cards,
            ]
          ),
        },
        dataFetched: {
          ...state.dataFetched,
          blog: true,
        },
      };

    case FETCH_ARTICLE_BLOG_VIEW_REQUESTED:
      return state;
    case FETCH_ARTICLE_BLOG_VIEW_SUCCESS:
      return {
        ...state,
        blogView: {
          ...state.blogView,
          cards: state.blogView?.cards ? ([
            ...state.blogView.cards.filter((card) => card.id !== payload.data.id),
            Object.assign(...state.blogView.cards.filter((card) => card.id === payload.data.id), payload.data),
          ]) : (
            [payload.data]
          ),
        },
        dataFetched: {
          ...state.dataFetched,
          article: [...state.dataFetched.article, payload.data.id],
        },
      };

    default:
      return state;
  }
};

export default dataReducer;
