import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import * as serviceWorker from './serviceWorker';
import Root from './views/Root';

const style = {
  overflow: 'hidden',
  position: 'relative',
  zIndex: '0',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <div style={style}>
        <Root />
      </div>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
