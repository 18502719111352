import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;     
    }

    html {
        font-size: 62.5%;
    }

    body {
        overscroll-behavior-y: contain;
        overflow-x: hidden;
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.black};
        font-family: ${({ theme }) => theme.fontFamily.standard};
        font-size: ${({ theme }) => theme.fontSize.m};
        font-weight: ${({ theme }) => theme.fontWeight.light};
    }
`;

export default GlobalStyle;
