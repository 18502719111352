import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from 'breakpoints';
import debounce from 'lodash/debounce';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const StyledSpan = styled.span`
  display: inline-block;
  white-space: pre;
  transform-origin: 5%;
`;

const StyedParagraph = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.serif};
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 1.5;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  margin: 0 auto;
  padding: 1rem .5rem;
  padding-bottom: 3rem;

  @media screen and (min-width: ${breakpoints.XS}) {
    font-size: ${({ theme }) => theme.fontSize.m};
    max-width: 1200px;
    padding-bottom: 1rem;
  }

  @media screen and (min-width: ${breakpoints.S}) {
    font-size: ${({ theme }) => theme.fontSize.l};
    padding: 2rem .5rem;
  }

  @media screen and (min-width: ${breakpoints.M}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
    padding: 4rem .5rem;
  }

  @media screen and (min-width: ${breakpoints.L}) {
    padding: 7rem .5rem;
  }
`;

const SerifParagraph = ({ children }) => {
  const textRef = useRef(null);
  const animationRef = useRef(null);
  const [width, setWidth] = useState(null);
  const [done, setDone] = useState(false);

  const getWidth = () => {
    setWidth(document.body.clientWidth);
    setDone(false);
  };

  const debounced = useRef(debounce(getWidth, 200)).current;

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', debounced);

    return () => window.removeEventListener('resize', debounced);
  }, [debounced]);

  useEffect(() => {
    if (!children) return;

    if (!done) {
      const lettersArr = textRef.current.children;
      let sumLettersWidth = 0;
      let letter = 0;
      const maxWidth = textRef.current.getBoundingClientRect().width * 0.70;
      const changeBrs = () => {
        for (let i = 0; i < lettersArr.length; i += 1) {
          if (lettersArr[i].innerHTML === '') {
            lettersArr[i].outerHTML = '<span> </span>';
          }
        }
      };
      changeBrs();
      const changeSpaces = () => {
        for (letter; letter < lettersArr.length; letter += 1) {
          sumLettersWidth += lettersArr[letter].getBoundingClientRect().width;
          if (lettersArr[letter].textContent === ' ' && sumLettersWidth >= maxWidth) {
            lettersArr[letter].outerHTML = '</br>';
            sumLettersWidth = 0;
            changeSpaces();
            break;
          }
        }
      };
      changeSpaces();
      setDone(true);
    }
  }, [width, done, children]);

  useEffect(() => {
    if (!children) return;

    animationRef.current = gsap.from(textRef.current.children, {
      delay: 0.5,
      duration: 0.15,
      opacity: 0,
      scaleX: 4,
      scaleY: 2,
      rotateY: '-90deg',
      rotate: '75deg',
      y: '50%',
      stagger: 0.02,
      onComplete: getWidth,
      scrollTrigger: {
        trigger: textRef.current,
        start: 'top 83%',
      },
    });

    return () => ScrollTrigger.getAll().forEach((t) => t.kill());
  }, [children]);

  return (
    <StyedParagraph
      ref={textRef}
    >
      {children.split('').map((word, index) => (
        <StyledSpan
          key={word + index}
        >
          {word}
          {/* {' '} */}
        </StyledSpan>
      ))}
    </StyedParagraph>
  );
};

SerifParagraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SerifParagraph;
